import React from "react";
import Layout from "@components/Shared/Templates/Layout";
import LeadershipProfile, { ContentfulNode } from "@components/Leadership/Profile/Profile";
import MetaHelmet, { MetaContent } from "@components/Shared/model/MetaHelmet";
import { LanguageContent } from "@components/Shared/model/LanguageContent";
import { useApplication } from "@ryerson/frontend.application";

export default (props: any) => {
	const {
		localization: { language },
	} = useApplication();
	if (
		props &&
		props.pageResources &&
		props.pageResources.json &&
		props.pageResources.json.pageContext &&
		props.pageResources.json.pageContext.contentfulData
	) {
		const data = props.pageResources.json.pageContext.contentfulData as ContentfulNode[];
		let Meta: LanguageContent<MetaContent> = {
			en: {
				title: "",
				description: "",
				url: "",
				company: false,
			},
			fr: {
				title: "",
				description: "",
				url: "",
				company: false,
			},
			es: {
				title: "",
				description: "",
				url: "",
				company: false,
			},
		};
		let structuredContent: any = {};
		data.forEach((d: any) => {
			let obj = {
				title: d.node.seoTitle,
				description: d.node.seoDescription,
				imageUrl:
					d.node.picture && d.node.picture.file && d.node.picture.file.url
						? d.node.picture.file.url
						: "/images/RyersonLogo.png",
				url: "/about-us/leadership-team/" + d.node.slug,
				company: false,
			};

			if (d.node.node_locale.split("-").shift() === "en") {
				Meta.en = obj;
				structuredContent = {
					"@context": "https://schema.org/",
					"@type": "Person",
					name: d.node.fullName,
					url: "/about-us/leadership-team/" + d.node.slug,
					image:
						d.node.picture && d.node.picture.file && d.node.picture.file.url
							? d.node.picture.file.url
							: "/images/RyersonLogo.png",
					jobTitle: d.node.title,
					worksFor: {
						"@type": "Organization",
						name: "Ryerson",
					},
				};
			} else if (d.node.node_locale.split("-").shift() === "fr") {
				Meta.fr = obj;
			} else {
				Meta.es = obj;
			}
		});
		return (
			<Layout>
				<MetaHelmet content={Meta[language]}>
					<script type="application/ld+json">{JSON.stringify(structuredContent)}</script>
				</MetaHelmet>
				<LeadershipProfile contentfulData={data} />
			</Layout>
		);
	} else {
		return <></>;
	}
};
