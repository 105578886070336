import { LanguageContent } from "@components/Shared/model/LanguageContent";
export const LanguageFilters: LanguageContent<any> = {
	en: {
		Aluminum: "Aluminum",
		Stainless: "Stainless",
		Steel: "Steel",
		Alloy: "Alloy",
		Other: "Other",
		Bar: "Bar",
		"Expanded Metal": "Expanded Metal",
		Pipe: "Pipe",
		Plate: "Plate",
		Sheet: "Sheet",
		Structural: "Structural",
		Treadplate: "Treadplate",
		Tube: "Tube",
		Nickel: "Nickel",
		"Tool Steel": "Tool Steel",
		"Brass/Copper/Bronze": "Brass/Copper/Bronze",
		"Bar/Tube/Structural": "Bar/Tube/Structural",
		"Advanced Processing": "Advanced Processing",
		Bevel: "Bevel",
		"Cut To Size": "Cut To Size",
		Form: "Form",
		Roll: "Roll",
		Shear: "Shear",
		Slit: "Slit",
		Drill: "Drill",
		"Laser Cut": "Laser Cut",
		"Location Type": "Location Type",
		"Ryerson Facility": "Ryerson Facility",
		"Ryerson Family of Companies Facility": "Ryerson Family of Companies Facility",
	},
	fr: {
		Aluminum: "Aluminum",
		Stainless: "Stainless",
		Steel: "Steel",
		Alloy: "Alloy",
		Other: "Other",
		Bar: "Bar",
		"Expanded Metal": "Expanded Metal",
		Pipe: "Pipe",
		Plate: "Plate",
		Sheet: "Sheet",
		Structural: "Structural",
		Treadplate: "Treadplate",
		Tube: "Tube",
		Nickel: "Nickel",
		"Tool Steel": "Tool Steel",
		"Brass/Copper/Bronze": "Brass/Copper/Bronze",
		"Bar/Tube/Structural": "Bar/Tube/Structural",
		"Advanced Processing": "Advanced Processing",
		Bevel: "Bevel",
		"Cut To Size": "Cut To Size",
		Form: "Form",
		Roll: "Roll",
		Shear: "Shear",
		Slit: "Slit",
		Drill: "Drill",
		"Laser Cut": "Laser Cut",
		"Location Type": "Location Type",
		"Ryerson Facility": "Ryerson Facility",
		"Ryerson Family of Companies Facility": "Ryerson Family of Companies Facility",
	},
	es: {
		Aluminum: "Aluminum",
		Stainless: "Stainless",
		Steel: "Steel",
		Alloy: "Alloy",
		Other: "Other",
		Bar: "Bar",
		"Expanded Metal": "Expanded Metal",
		Pipe: "Pipe",
		Plate: "Plate",
		Sheet: "Sheet",
		Structural: "Structural",
		Treadplate: "Treadplate",
		Tube: "Tube",
		Nickel: "Nickel",
		"Tool Steel": "Tool Steel",
		"Brass/Copper/Bronze": "Brass/Copper/Bronze",
		"Bar/Tube/Structural": "Bar/Tube/Structural",
		"Advanced Processing": "Advanced Processing",
		Bevel: "Bevel",
		"Cut To Size": "Cut To Size",
		"Cut To Length": "Cut To Length",
		Form: "Form",
		Roll: "Roll",
		Shear: "Shear",
		Slit: "Slit",
		Drill: "Drill",
		"Laser Cut": "Laser Cut",
		"Location Type": "Location Type",
		"Ryerson Facility": "Ryerson Facility",
		"Ryerson Family of Companies Facility": "Ryerson Family of Companies Facility",
	},
};

export const ProcessFilters: any = [
	{
		title: "Plate",
		filters: ["Advanced Processing", "Bevel", "Form", "Roll"],
	},
	{
		title: "Sheet",
		filters: ["Advanced Processing", "Shear", "Slit"],
	},
	{
		title: "Bar/Tube/Structural",
		filters: ["Advanced Processing", "Cut To Length", "Drill", "Laser Cut"],
	},
];

export const LocationTypeFilters: any = [
	"Ryerson Facility",
	"Ryerson Family of Companies Facility",
];

export const MaterialFilters: any = [
	{
		title: "Aluminum",
		filters: [
			"Bar",
			"Expanded Metal",
			"Pipe",
			"Plate",
			"Sheet",
			"Structural",
			"Treadplate",
			"Tube",
			"Plate Cut To Size",
			"Sheet Cut To Length",
			"Bar/Tube/Structural Cut To Length",
		],
	},
	{
		title: "Stainless",
		filters: [
			"Bar",
			"Expanded Metal",
			"Pipe",
			"Plate",
			"Sheet",
			"Structural",
			"Treadplate",
			"Tube",
			"Plate Cut To Size",
			"Sheet Cut To Length",
			"Bar/Tube/Structural Cut To Length",
		],
	},
	{
		title: "Steel",
		filters: [
			"Bar",
			"Expanded Metal",
			"Flooring",
			"Pipe",
			"Plate",
			"Sheet",
			"Structural",
			"Treadplate",
			"Tube",
			"Plate Cut To Size",
			"Sheet Cut To Length",
			"Bar/Tube/Structural Cut To Length",
		],
	},
	{
		title: "Alloy",
		filters: [
			"Plate",
			"Sheet",
			"Tube",
			"Plate Cut To Size",
			"Sheet Cut To Length",
			"Bar/Tube/Structural Cut To Length",
		],
	},
	{
		title: "Other",
		filters: ["Nickel", "Tool Steel", "Brass/Copper/Bronze"],
	},
];
