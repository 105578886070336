import { StaticPageContent } from "@components/Shared/model/StaticPageContent";
import { LanguageContent } from "@components/Shared/model/LanguageContent";
import { ValueAddedBenefitsProperties } from "@components/Industries/ValueAddedBenefits/ValueAddedBenefits";
import { HeroProps } from "@components/Industries/Hero/Hero";
import { RelatedSolutionsCapabilitiesAndProductsProperties } from "@components/Industries/RelatedSolutionsCapabilitiesAndProducts/RelatedSolutionsCapabilitiesAndProducts";
import { MetaContent } from "@components/Shared/model/MetaHelmet";

export const MetaObject: LanguageContent<MetaContent> = {
	en: {
		title: "Precision Metals for Medical Innovations - Ryerson",
		description:
			"Explore advanced metals and alloys vital in the development of medical equipment and healthcare systems. Enhancing healthcare through cutting-edge materials.",
		url: "/medical-systems",
		imageUrl: "/images/industries/MedicalSystemsHeroImage.jpg",
		company: true,
	},
	fr: {
		title: "Precision Metals for Medical Innovations - Ryerson",
		description:
			"Explore advanced metals and alloys vital in the development of medical equipment and healthcare systems. Enhancing healthcare through cutting-edge materials.",
		url: "/medical-systems",
		imageUrl: "/images/industries/MedicalSystemsHeroImage.jpg",
		company: true,
	},
	es: {
		title: "Precision Metals for Medical Innovations - Ryerson",
		description:
			"Explore advanced metals and alloys vital in the development of medical equipment and healthcare systems. Enhancing healthcare through cutting-edge materials.",
		url: "/medical-systems",
		imageUrl: "/images/industries/MedicalSystemsHeroImage.jpg",
		company: true,
	},
};

export const HeroContent: LanguageContent<HeroProps> = {
	en: {
		heroTitle: "Medical, Health & Safety",
		heroDescription: [
			"Medical and safety product manufacturers navigate a demanding landscape where adaptability and supply chain reliability are paramount. Ryerson excels in meeting these challenges, providing solutions tailored to diverse needs. From hospital beds to IV poles and medical cabinets, our commitment to flexibility and supply chain resilience ensures consistent support for the healthcare industry.",
			"Our 70,000+ item-catalog ensures the right material is available to meet a broad product portfolio. Whether it is a high-volume single location, a multiple location solution, or the need for just-in-time production, Ryerson can orchestrate the most optimal supply chain."
		],
		buttonLabel: "Contact a Professional",
		hasVideo: false,
		heroImageUrl: "/images/industries/MedicalSystemsHeroImage.jpg",
		heroVideoId: "",
		isWatermarkHero: true,
		watermarkUrl: "/images/industries/MedicalSystemsHeroImage.jpg",
	},
	fr: {
		heroTitle: "Médecine, santé et sécurité",
		heroDescription: [
			"Les fabricants de produits médicaux et de sécurité évoluent dans un environnement exigeant où l'adaptabilité et la fiabilité de la chaîne d'approvisionnement sont primordiales. Ryerson excelle à relever ces défis, en proposant des solutions adaptées à divers besoins. Des lits d'hôpitaux aux supports pour intraveineuse en passant par les armoires médicales, notre engagement en faveur de la flexibilité et de la résilience de la chaîne d'approvisionnement garantit un soutien constant au secteur de la santé.",
			"Notre catalogue de plus de 70,000 articles garantit la disponibilité du matériau approprié pour répondre à une large gamme de produits. Qu'il s'agisse d'un emplacement unique à volume élevé, d'une solution sur plusieurs emplacements ou d'une production juste à temps, Ryerson peut orchestrer la chaîne d'approvisionnement optimale.",
		],
		buttonLabel: "Communiquer avec un expert",
		hasVideo: false,
		heroImageUrl: "/images/industries/MedicalSystemsHeroImage.jpg",
		heroVideoId: "",
		isWatermarkHero: true,
		watermarkUrl: "/images/industries/MedicalSystemsHeroImage.jpg",
	},
	es: {
		heroTitle: "Salud, seguridad y medio ambiente",
		heroDescription: [
			"Los fabricantes de productos médicos y de seguridad navegan en un entorno demandante donde la adaptabilidad y una cadena de suministro son fundamentales. Ryerson sobresale al estar a la altura de estos desafíos, ofreciendo soluciones personalizadas para diversas necesidades. Desde camas de hospital hasta portasueros y gabinetes médicos, nuestro compromiso con la flexibilidad y la resiliencia de la cadena de suministro asegura el apoyo constante para la industria de servicios de salud.",
			"Nuestro catálogo de más de 70,000 artículos asegura que el material correcto esté disponible para satisfacer una cartera amplia de productos. Ya sea una solución de alto volumen y ubicación individual, de ubicación múltiple o la necesidad de producción justo a tiempo, Ryerson puede organizar la cadena de suministro óptima.",
		],
		buttonLabel: "Ponerse en contacto con un experto",
		hasVideo: false,
		heroImageUrl: "/images/industries/MedicalSystemsHeroImage.jpg",
		heroVideoId: "",
		isWatermarkHero: true,
		watermarkUrl: "/images/industries/MedicalSystemsHeroImage.jpg",
	},
};

export const ValueAddedBenefitsContent: LanguageContent<ValueAddedBenefitsProperties> = {
	en: {
		title: "Value-Added Benefits",
		mainDescription: `The demands of manufacturing medical equipment requires the right process at the right time. Our range of processing and fabrication services provides critical assistance to those answering the call for mission-critical products.`,
		buttonLabel: "Let's Talk!",
		secondaryContent: [
			{
				title: "Tube Laser",
				description: `Complete customization and cut-to-length capabilities in just one step, with finished or semi-finished parts produced from raw material. We simplify the process by eliminating the need to coordinate multiple orders and vendors—handling everything from material procurement to part delivery.`,
				imageUrl: "/images/industries/ev/TubeLaserUpdate.jpg",
				showImageOnDesktop: false,
				showImageOnMobile: true,
			},
			{
				title: "Advanced Processing",
				description: `When you need solutions to complex challenges, our team offers more than just a list of fabrication capabilities. Fully invested in your success, we evaluate each step of the process and offer custom solutions focused on saving you time and money.`,
				imageUrl: "/images/industries/heavy-equipment/AdvancedProcessingInvertColors.jpg",
				showImageOnDesktop: true,
				showImageOnMobile: true,
			},
		],
	},
	fr: {
		title: "Avantages à valeur ajoutée",
		mainDescription: `Les exigences de la fabrication d'équipements médicaux nécessitent le bon processus au bon moment. Notre gamme de services de traitement et de fabrication offre une assistance essentielle afin que vous disposiez des produits ayant une importance critique pour vos activités.`,
		buttonLabel: "Discutons",
		secondaryContent: [
			{
				title: "Laser à tubes",
				description: `Personnalisation complète et capacités de coupe à longueur en une seule étape, avec des pièces finies ou semi-finies produites à partir de matières premières. Nous simplifions le processus en éliminant le besoin de coordonner plusieurs commandes et auprès de plusieurs fournisseurs, en gérant tout, de l'approvisionnement en matériaux à la livraison des pièces.`,
				imageUrl: "/images/industries/ev/TubeLaserUpdate.jpg",
				showImageOnDesktop: false,
				showImageOnMobile: true,
			},
			{
				title: "Transformation évoluée",
				description: `Lorsque vous avez besoin de solutions répondant à des défis complexes, notre équipe propose plus qu'une simple liste de capacités de fabrication. Nous avons à cœur votre succès. C'est pourquoi nous évaluons chaque étape du processus et vous offrons des solutions personnalisées pour vous faire économiser du temps et de l'argent.`,
				imageUrl: "/images/industries/heavy-equipment/AdvancedProcessingInvertColors.jpg",
				showImageOnDesktop: true,
				showImageOnMobile: true,
			},
		],
	},
	es: {
		title: "Ventajas de valor añadido",
		mainDescription: `Las demandas de la fabricación de equipos médicos requieren el proceso correcto en el momento correcto. Nuestro rango de procesamiento y servicios de fabricación ofrecen asistencia crítica para aquellos que responden al llamado para productos críticos.`,
		buttonLabel: "Hablemos",
		secondaryContent: [
			{
				title: "Láser de tubos",
				description: `Posibilidades de personalización completa y corte a longitud, con piezas acabadas o semiacabadas producidas a partir de materias primas. Simplificamos el proceso al eliminar la necesidad de coordinar un gran número de órdenes y vendedores, al manejar todo desde la adquisición de materiales hasta la entrega de piezas.`,
				imageUrl: "/images/industries/ev/TubeLaserUpdate.jpg",
				showImageOnDesktop: false,
				showImageOnMobile: true,
			},
			{
				title: "Procesamiento avanzado",
				description: `Cuando necesita soluciones para retos difíciles, nuestro equipo le ofrece mucho más que una simple lista de capacidades de fabricación. Totalmente comprometidos con su éxito, evaluamos todos los pasos del proceso y ofrecemos soluciones personalizadas pensadas para que ahorre tiempo y dinero.`,
				imageUrl: "/images/industries/heavy-equipment/AdvancedProcessingInvertColors.jpg",
				showImageOnDesktop: true,
				showImageOnMobile: true,
			},
		],
	},
};

export const RelatedSolutionsCapabilitiesContent: LanguageContent<RelatedSolutionsCapabilitiesAndProductsProperties> =
	{
		en: {
			title: "Related Solutions, Capabilities, and Products",
			mainDescription: `Diversity in product portfolio places pressure on the ability to find the products you need when needed. Our broad selection of products and capabilities eases this burden on medical manufacturers.`,
			secondaryContent: [
				{
					title: "Solutions & Capabilities",
					jumpToLabel: "More Metal Solutions",
					jumpToLink: "/metal-solutions/",
					bullets: [
						{
							title: "Tube Laser",
							iconType: "capabilities-tube-laser",
							link: "/metal-solutions/capabilities/bar-tube-structural-processing/tube-laser",
						},
						{
							title: "Plate Processing",
							link: "/metal-solutions/capabilities/plate-processing/",
							iconType: "solution-fabrication",
						},
						{
							title: "Forming",
							link: "/metal-solutions/capabilities/fabrication",
							iconType: "capabilities-fabrication",
						},
					],
				},
				{
					title: "Products",
					jumpToLabel: "More Metal Products",
					jumpToLink: "/store",
					bullets: [
						{
							title: "Steel Round Tube",
							imageUrl: "/static-assets/images/renderings/CarbonTubeRound.png",
							link: "/steel/steel-tube-pipe",
						},
						{
							title: "Stainless Flat Bar",
							imageUrl: "/static-assets/images/renderings/StainlessBarFlat.png",
							link: "/stainless/stainless-bar",
						},
						{
							title: "Aluminum Sheet",
							imageUrl: "/static-assets/images/renderings/AluminumSheet.png",
							link: "/aluminum/aluminum-sheet/",
						},
					],
				},
			],
		},
		fr: {
			title: "Solutions, capacités et produits connexes",
			mainDescription: `La diversité de la gamme de produits exerce une pression sur la capacité à obtenir les produits dont vous avez besoin en cas de nécessité. Notre vaste sélection de produits et de capacités allège le fardeau des entreprises dans le secteur de la fabrication d'équipement médical.`,
			secondaryContent: [
				{
					title: "Solutions et capacités",
					jumpToLabel: "Plus de solutions métallurgiques et de capacités",
					jumpToLink: "/metal-solutions/",
					bullets: [
						{
							title: "Laser à tubes",
							iconType: "capabilities-tube-laser",
							link: "/metal-solutions/capabilities/bar-tube-structural-processing/tube-laser",
						},
						{
							title: "Traitement de plaques",
							imageUrl: "/static-assets/images/renderings/CarbonPlate.png",
							link: "/steel/steel-plate",
						},
						{
							title: "Formage",
							link: "/metal-solutions/capabilities/fabrication",
							iconType: "capabilities-fabrication",
						},
					],
				},
				{
					title: "Produits",
					jumpToLabel: "Plus de produits métalliques",
					jumpToLink: "/store",
					bullets: [
						{
							title: "Tube rond en acier",
							imageUrl: "/static-assets/images/renderings/CarbonTubeRound.png",
							link: "/steel/steel-tube-pipe",
						},
						{
							title: "Barre plate d'acier inoxydable",
							imageUrl: "/static-assets/images/renderings/StainlessBarFlat.png",
							link: "/stainless/stainless-bar",
						},
						{
							title: "Feuille d'aluminium",
							imageUrl: "/static-assets/images/renderings/AluminumSheet.png",
							link: "/aluminum/aluminum-sheet/",
						},
					],
				},
			],
		},
		es: {
			title: "Soluciones, capacidades y productos relacionados",
			mainDescription: `La diversidad en la cartera de productos crea presión en la capacidad de encontrar los productos que necesita cuando los necesita. Nuestra amplia selección de productos y capacidades alivia esta presión para los fabricantes de equipos médicos.`,
			secondaryContent: [
				{
					title: "Soluciones y capacidades",
					jumpToLabel: "Más soluciones y capacidades de metal",
					jumpToLink: "/metal-solutions/",
					bullets: [
						{
							title: "Corte con láser de tubos",
							iconType: "capabilities-tube-laser",
							link: "/metal-solutions/capabilities/bar-tube-structural-processing/tube-laser",
						},
						{
							title: "Procesamiento de placas",
							link: "/metal-solutions/capabilities/plate-processing/",
							iconType: "solution-fabrication",
						},
						{
							title: "Formado",
							link: "/metal-solutions/capabilities/fabrication",
							iconType: "capabilities-fabrication",
						},
					],
				},
				{
					title: "Productos",
					jumpToLabel: "Más productos de metal",
					jumpToLink: "/store",
					bullets: [
						{
							title: "Tubo redondo de acero",
							imageUrl: "/static-assets/images/renderings/CarbonTubeRound.png",
							link: "/steel/steel-tube-pipe",
						},
						{
							title: "Barra Plana de Acero Inoxidable",
							imageUrl: "/static-assets/images/renderings/StainlessBarFlat.png",
							link: "/stainless/stainless-bar",
						},
						{
							title: "Hoja de Aluminio",
							imageUrl: "/static-assets/images/renderings/AluminumSheet.png",
							link: "/aluminum/aluminum-sheet/",
						},
					],
				},
			],
		},
	};

export const PageContent: StaticPageContent = {
	meta: MetaObject,
	content: [HeroContent, ValueAddedBenefitsContent, RelatedSolutionsCapabilitiesContent],
};
