import { StaticPageContent } from "@components/Shared/model/StaticPageContent";
import { LanguageContent } from "@components/Shared/model/LanguageContent";
import { InOurDNAContentProps } from "@components/Shared/InOurDNA/InOurDNA";
import { EqualOpportunityContent } from "@components/Shared/DiversityAndInclusion/EqualOpportunity/EqualOpportunity";
import { MetaContent } from "@components/Shared/model/MetaHelmet";
import { TrainingAndDevelopmentHeroContent } from "@components/TrainingAndDevelopment/Hero/Hero";
import { RyersonAcademyContent } from "@components/TrainingAndDevelopment/RyersonAcademy/RyersonAcademy";
import { PaidInternshipContent } from "@components/TrainingAndDevelopment/PaidInternship/PaidInternship";

export const MetaObject: LanguageContent<MetaContent> = {
	en: {
		title: "Diversity and Inclusion - Ryerson",
		description:
			"Ryerson values and strives to build a diverse and inclusive workplace of associates from varied cultures, educational backgrounds, and experiences.",
		url: `/about-us/careers/working-at-ryerson/diversity-and-inclusion`,
		company: true,
	},
	fr: {
		title: "Diversity and Inclusion - Ryerson",
		description:
			"Ryerson values and strives to build a diverse and inclusive workplace of associates from varied cultures, educational backgrounds, and experiences.",
		url: `/about-us/careers/working-at-ryerson/diversity-and-inclusion`,
		company: true,
	},
	es: {
		title: "Diversity and Inclusion - Ryerson",
		description:
			"Ryerson values and strives to build a diverse and inclusive workplace of associates from varied cultures, educational backgrounds, and experiences.",
		url: `/about-us/careers/working-at-ryerson/diversity-and-inclusion`,
		company: true,
	},
};

export const HeroContent: LanguageContent<TrainingAndDevelopmentHeroContent> = {
	en: {
		title: "Diversity, Equity, & Inclusion",
		description:
			"Diversity, Equity & Inclusion (DEI) is a core value of Ryerson’s culture. Diverse perspectives and ideas are essential to the sustainability of our business and are key to driving a successful corporate strategy by unlocking the great potential of our workforce and attracting and retaining talent.",
		quote: "We recognize that each employee’s unique experiences, perspectives, and viewpoints add value to our ability to work together, the ultimate basis for a sustainable company.",
		quoteAuthor: "Sarah Moran",
		quoteAuthorTitle: "Sales General Manager – DEI Board Co-Chair",
		quoteAuthorImage: "/images/diversity-and-inclusion/QuoteAuthor.jpg",
	},
	fr: {
		title: "Diversité, équité et inclusion",
		description:
			"La diversité, l’équité et l’inclusion (DEI) sont des valeurs fondamentales de la culture de Ryerson. La diversité des perspectives et des idées est essentielle au développement durable de notre entreprise et constitue la clé de la réussite de notre stratégie d’entreprise en libérant l’immense potentiel de notre personnel et en attirant et conservant les personnes de talent.",
		quote: "Nous reconnaissons que les expériences, perspectives et points de vue uniques de chaque employé ajoutent de la valeur à notre capacité à travailler ensemble, la base ultime d’une entreprise durable.",
		quoteAuthor: "Sarah Moran",
		quoteAuthorTitle:
			"Directeur général des ventes – Coprésident du conseil d’administration de DEI",
		quoteAuthorImage: "/images/diversity-and-inclusion/QuoteAuthor.jpg",
	},
	es: {
		title: "Diversidad, equidad e inclusión",
		description:
			"La diversidad, la equidad y la inclusión (DEI, por sus siglas en inglés) son un valor fundamental de la cultura de Ryerson. Diversas perspectivas e ideas son esenciales para la sustentabilidad de nuestro negocio y son claves para impulsar una estrategia corporativa exitosa al liberar el gran potencial de nuestra fuerza de trabajo y atraer y retener el talento.",
		quote: "Reconocemos que las experiencias, perspectivas y puntos de vista únicos de cada empleado agregan valor a nuestra capacidad de trabajar juntos, la base fundamental para una compañía sustentable.",
		quoteAuthor: "Sarah Moran",
		quoteAuthorTitle: "Gerente General de Ventas - Copresidenta de la Junta de DEI",
		quoteAuthorImage: "/images/diversity-and-inclusion/QuoteAuthor.jpg",
	},
};

export const RyersonAcademy: LanguageContent<RyersonAcademyContent> = {
	en: {
		title: "DEI is a Priority",
		description: `Ryerson has established a company-wide diversity and inclusion team dedicated to supporting solidarity and equality within our organization.`,
		actionUrl: "/about-us/careers/open-positions/",
		actionLabel: "Explore Open Opportunities",
		cards: [
			{
				imageUrl: "/images/diversity-and-inclusion/ryersonhistory1957.jpg",
				description: `Ryerson’s commitment to equality started in 1957, when we published an Equal Opportunity Policy before it was legally required or even a common corporate practice.`,
			},
			{
				imageUrl: "/images/diversity-and-inclusion/DEIRecruit.jpg",
				description: `Diversity, Equity, and Inclusion are integrated into our organizational practices, from internal communications to benefits to recruitment.`,
			},
			{
				imageUrl: "/images/diversity-and-inclusion/deiLogoUpdated.png",
				description: `The Ryerson DEI Council fosters an environment that values diversity of experiences and perspectives and encourages inclusivity.`,
			},
		],
	},
	fr: {
		title: "La DEI est une priorité",
		description: `Ryerson a mis sur pied une équipe spécialisée en diversité et inclusion pour l’ensemble de l’entreprise, qui favorise la solidarité et l’égalité au sein de notre organisation.`,
		actionUrl: "/about-us/careers/open-positions/",
		actionLabel: "Explorer les opportunités ouvertes",
		cards: [
			{
				imageUrl: "/images/diversity-and-inclusion/ryersonhistory1957.jpg",
				description: `L’engagement de Ryerson envers l'égalité a commencé en 1957, lorsque nous avons publié une Politique d’égalité des chances, bien avant que cela ne soit exigé par la loi ou ne devienne une pratique courante dans les entreprises.`,
			},
			{
				imageUrl: "/images/diversity-and-inclusion/DEIRecruit.jpg",
				description: `La diversité, l’équité et l’inclusion sont intégrées dans nos pratiques organisationnelles, des communications internes au recrutement en passant par les avantages sociaux.`,
			},
			{
				imageUrl: "/images/diversity-and-inclusion/deiLogoUpdated.png",
				description: `Le Conseil de DEI de Ryerson favorise un environnement qui valorise la diversité des expériences et des perspectives, et encourage l’inclusivité.`,
			},
		],
	},
	es: {
		title: "DEI es una prioridad",
		description: `Ryerson ha establecido un equipo de diversidad e inclusión en toda la empresa dedicado a apoyar la solidaridad y la igualdad dentro de nuestra organización.`,
		actionUrl: "/about-us/careers/open-positions/",
		actionLabel: "Explorar oportunidades abiertas",
		cards: [
			{
				imageUrl: "/images/diversity-and-inclusion/ryersonhistory1957.jpg",
				description: `El compromiso de Ryerson con la igualdad comenzó en 1957, cuando publicamos una Política de oportunidades iguales antes de que fuera requerida legalmente o incluso una práctica común.`,
			},
			{
				imageUrl: "/images/diversity-and-inclusion/DEIRecruit.jpg",
				description: `La diversidad, la equidad y la inclusión están integradas en las prácticas de nuestra organización, desde las comunicaciones internas hasta los beneficios y el reclutamiento.`,
			},
			{
				imageUrl: "/images/diversity-and-inclusion/deiLogoUpdated.png",
				description: `El Consejo de DEI de Ryerson fomenta un entorno que valora la diversidad de las experiencias y perspectivas y motiva la inclusión.`,
			},
		],
	},
};

export const PaidInternships: LanguageContent<PaidInternshipContent> = {
	en: {
		title: "Disability",
		description: `If you are a person with a disability and need assistance during the application process, please contact our Accommodation Line at 312-292-5120 or contact us. You will be prompted to leave a message and a member of our human resources department will respond appropriately. Please note, messages left for reasons other than application assistance will not be returned.`,
		imageUrl: "/images/diversity-and-inclusion/Disability.jpg",
		actionUrl: "/contact-us#careers",
		actionLabel: "Contact Us",
	},
	fr: {
		title: "Incapacité",
		description: `Si vous avez une incapacité et avez besoin d’aide pour poser votre candidature, veuillez appeler notre ligne d’accommodement au 312-292-5120 ou communiquer avec nous. On vous demandera de laisser un message et un membre du département des ressources humaines vous rappellera. Veuillez noter qu’on ne donnera pas suite aux messages concernant autre chose que le processus de candidature.`,
		imageUrl: "/images/diversity-and-inclusion/Disability.jpg",
		actionUrl: "/contact-us#careers",
		actionLabel: "Nous contacter",
	},
	es: {
		title: "Discapacidad",
		description: `Si usted tiene una discapacidad y necesita ayuda durante el proceso de solicitud, comuníquese con nuestra Línea de asistencia al 312-292-5120 o contáctenos. Se le pedirá que deje un mensaje y un miembro de nuestro departamento de recursos humanos responderá de manera correspondiente. Tenga en cuenta que no se responderá a mensajes que se dejen por motivos distintos a la asistencia con la solicitud.`,
		imageUrl: "/images/diversity-and-inclusion/Disability.jpg",
		actionUrl: "/contact-us#careers",
		actionLabel: "Contacta con nosotros",
	},
};

export const EqualOpportunityEmployerContent: LanguageContent<EqualOpportunityContent> = {
	en: {
		title: "Ryerson is an Equal Opportunity Employer",
		description: `Ryerson recruits, hires, trains, and promotes in accordance with the principles of equal employment opportunity and ensures that no applicant for employment or employee is deprived of the opportunity to work and improve their position by reasons that are defined under our EEOC policy.`,
		imageUrl: "/images/training-and-development/EducationPartnershipDesktop.jpg",
		mobileImageUrl: "/images/training-and-development/EducationPartnershipMobile.jpg",
		listTitle: "Learn more about these topics",
		list: [
			{
				topic: "Pay Transparency",
				link: "https://www.dol.gov/sites/dolgov/files/ofccp/pdf/pay-transp_%20English_formattedESQA508c.pdf",
			},
			{
				topic: "EEO Is The Law",
				link: "https://www.dol.gov/sites/dolgov/files/ofccp/regs/compliance/posters/pdf/eeopost.pdf",
			},
			{
				topic: "EEO Poster Supplement",
				link: "https://www.dol.gov/sites/dolgov/files/ofccp/regs/compliance/posters/pdf/OFCCP_EEO_Supplement_Final_JRF_QA_508c.pdf",
			},
			{
				topic: "Employee Rights",
				link: "/static-assets/documents/pdfs/en/policies-terms-conditions/EEOCPolicy.pdf",
			},
		],
	},
	fr: {
		title: "Ryerson est un employeur qui promeut l’égalité des chances",
		description: `Ryerson recrute, embauche, forme et promeut conformément aux principes d'égalité des chances en matière d'emploi et veille à ce qu'aucun candidat à un emploi ou employé ne soit privé de la possibilité de travailler et d'améliorer sa position pour des raisons définies dans notre politique EEOC.`,
		imageUrl: "/images/training-and-development/EducationPartnershipDesktop.jpg",
		mobileImageUrl: "/images/training-and-development/EducationPartnershipMobile.jpg",
		listTitle: "En savoir plus sur ces sujets",
		list: [
			{
				topic: "Transparence des salaires",
				link: "https://www.dol.gov/sites/dolgov/files/ofccp/pdf/pay-transp_%20English_formattedESQA508c.pdf",
			},
			{
				topic: "EEO est la loi",
				link: "https://www.dol.gov/sites/dolgov/files/ofccp/regs/compliance/posters/pdf/eeopost.pdf",
			},
			{
				topic: "Supplément d'affiche EEO",
				link: "https://www.dol.gov/sites/dolgov/files/ofccp/regs/compliance/posters/pdf/OFCCP_EEO_Supplement_Final_JRF_QA_508c.pdf",
			},
			{
				topic: "Droits des employés",
				link: "/static-assets/documents/pdfs/fr/policies-terms-conditions/EEOCPolicy.pdf",
			},
		],
	},
	es: {
		title: "Ryerson es un empleador que ofrece igualdad de oportunidades",
		description: `Ryerson recluta, contrata, capacita y promueve de acuerdo con los principios de igualdad de oportunidades de empleo y garantiza que ningún solicitante de empleo o empleado se vea privado de la oportunidad de trabajar y mejorar su posición por motivos definidos en nuestra política de EEOC.`,
		imageUrl: "/images/training-and-development/EducationPartnershipDesktop.jpg",
		mobileImageUrl: "/images/training-and-development/EducationPartnershipMobile.jpg",
		listTitle: "Más información sobre estos temas",
		list: [
			{
				topic: "Transparencia de pago",
				link: "https://www.dol.gov/sites/dolgov/files/ofccp/pdf/pay-transp_%20English_formattedESQA508c.pdf",
			},
			{
				topic: "EEO es la ley",
				link: "https://www.dol.gov/sites/dolgov/files/ofccp/regs/compliance/posters/pdf/eeopost.pdf",
			},
			{
				topic: "Suplemento de póster de EEO",
				link: "https://www.dol.gov/sites/dolgov/files/ofccp/regs/compliance/posters/pdf/OFCCP_EEO_Supplement_Final_JRF_QA_508c.pdf",
			},
			{
				topic: "Derechos de los empleados",
				link: "/static-assets/documents/pdfs/es/policies-terms-conditions/EEOCPolicy_es.pdf",
			},
		],
	},
};

export const InOurDNAContent: LanguageContent<InOurDNAContentProps> = {
	en: {
		title: "In Our DNA",
		cards: [
			{
				title: "Training & Development",
				context:
					"At Ryerson, opportunities to grow and support for professional advancement are abundant through a broad range of training and educational opportunities.",
				backgroundUrl: "/images/ryerson-academy/TrainingAndDevelopment.jpg",
				link: "/about-us/careers/working-at-ryerson/training-and-development",
			},
			{
				title: "Ryerson Gives Back",
				context:
					"Ryerson invests in the community and our people in a variety of ways, from volunteerism to providing educational resources to help our employees and families advance their careers.",
				backgroundUrl: "/images/training-and-development/RGBUpdate.jpg",
				link: "/why-ryerson/ryerson-commitment/ryerson-gives-back",
			},
		],
	},
	fr: {
		title: "C’est Dans Notre ADN",
		cards: [
			{
				title: "Formation et Développement",
				context:
					"Chez Ryerson, profitez d’un abondant soutien à l’avancement professionnel et de plusieurs occasions pour grandir grâce à notre large éventail de possibilités de formation et d’éducation.",
				backgroundUrl: "/images/ryerson-academy/TrainingAndDevelopment.jpg",
				link: "/about-us/careers/working-at-ryerson/training-and-development",
			},
			{
				title: "Ryerson Redonne",
				context:
					"Ryerson investit dans la communauté et dans son personnel de diverses manières, du bénévolat à la fourniture de ressources éducatives, afin d’aider ses employés et leur famille à faire progresser leur carrière.",
				backgroundUrl: "/images/training-and-development/RGBUpdate.jpg",
				link: "/why-ryerson/ryerson-commitment/ryerson-gives-back",
			},
		],
	},
	es: {
		title: "En Nuestro ADN",
		cards: [
			{
				title: "Capacitación y Desarrollo",
				context:
					"En Ryerson, las oportunidades de crecer y el soporte para el progreso profesional son abundantes a través de una amplia gama de oportunidades de capacitación y educación.",
				backgroundUrl: "/images/ryerson-academy/TrainingAndDevelopment.jpg",
				link: "/about-us/careers/working-at-ryerson/training-and-development",
			},
			{
				title: "Ryerson Devuelve",
				context:
					"Ryerson invierte en la comunidad y en nuestra gente en muchas formas, desde el voluntariado hasta el proporcionar recursos educativos para ayudar a nuestros empleados y familias a crecer en sus carreras.",
				backgroundUrl: "/images/training-and-development/RGBUpdate.jpg",
				link: "/why-ryerson/ryerson-commitment/ryerson-gives-back",
			},
		],
	},
};

export const PageContent: StaticPageContent = {
	meta: MetaObject,
	content: [HeroContent, RyersonAcademy, PaidInternships, EqualOpportunityEmployerContent],
};
