import React from "react";
import { useTheme } from "@ryerson/frontend.theme";
import { Typography } from "@ryerson/frontend.typography";
import { Flex } from "@ryerson/frontend.layout";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { ButtonLink } from "@ryerson/frontend.navigation";
import { MetalMarketIntelligenceProps } from "../MetalMarketIntelligence";
import { Badge } from "@ryerson/frontend.notification";
import { BlogBadgeColor, TranslateBlogCategory } from "@components/Blog/Blog";
import { Link } from "gatsby";

const IntelDiv = styled.div`
	position: relative;
	display: flex;
	width: 50%;
`;

const BadgeContainer = styled.div`
	position: absolute;
	z-index: 1;
	width: 150px;
	left: 5%;
	bottom: 25%;
`;

const ButtonLinkContainer = styled.div`
	margin-top: 20px;
	margin-bottom: 60px;
`;

const MainImageContainer = styled.div`
	position: relative;
`;

const Intel = (props: any) => {
	return (
		<IntelDiv>
			<Link to={"/metal-resources/metal-market-intelligence/#" + props.url} replace>
				<img
					src={props.imageUrl}
					css={css`
						width: 60px;
						height: auto;
						margin-bottom: 40px;
					`}
				/>
				<BadgeContainer>
					<Badge
						badgeType="pill"
						type="primary"
						color={BlogBadgeColor(TranslateBlogCategory(props.label))}
						size="sm"
					>
						{props.label}
					</Badge>
				</BadgeContainer>
			</Link>
		</IntelDiv>
	);
};

const MetalMarketIntelligenceMobile: React.FC<MetalMarketIntelligenceProps> = (props) => {
	const { theme } = useTheme();

	return (
		<>
			<MainImageContainer>
				<img
					src={props.imageUrl}
					css={css`
						width: 100%;
						height: 180px;
						object-fit: cover;
						margin-top: 26px;
					`}
				/>
				<Typography
					variant="p"
					color={theme.colors.primary.white}
					size="md"
					css={css`
						position: absolute;
						z-index: 1;
						left: 17px;
						bottom: 25px;
						white-space: pre-line;
					`}
				>
					{props.mobileImageText}
				</Typography>
				<img
					src="/images/metal-resources/ryerson_logo_w.png"
					css={css`
						position: absolute;
						z-index: 1;
						bottom: 27px;
						left: 18px;
					`}
				/>
			</MainImageContainer>

			<Typography
				variant="p"
				css={css`
					margin-top: 24px;
					margin-bottom: 40px;
				`}
			>
				{props.description}
			</Typography>
			<Flex
				direction="row"
				wrap="wrap"
				css={css`
					width: 100%;
				`}
			>
				{props.metalMarketIntelligenceTypes.map(function (content: any, index: number) {
					return <Intel theme={theme} {...content} />;
				})}
			</Flex>
			<ButtonLinkContainer>
				<ButtonLink to={props.actionLink} label={props.actionText} />
			</ButtonLinkContainer>
		</>
	);
};
export default MetalMarketIntelligenceMobile;
