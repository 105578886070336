import { LanguageContent } from "@components/Shared/model/LanguageContent";
import { EnvProps } from "./Environment";

export const EnvironmentContent: LanguageContent<EnvProps> = {
	en: {
		background: "primary",
		title: "Environment",
		content:
			"Ryerson complies with EPA regulations in how it handles waste and annually allocates capital for energy efficient solutions in its warehouses. Ryerson's recycling programs are fully underway, from scrap metal to supplies, helping divert excess materials from landfills. As demonstrated by our Environmental Policy, we recognize the critical importance of environmental sustainability and acknowledge the responsibility we all bear toward our planet and communities.",
		imageUrl: "/EnvironmentSafety.jpg",
        fileUrl: "/static-assets/documents/pdfs/en/policies-terms-conditions/RyersonEnvironmentalPolicy.pdf",
        fileUrlLabel: "Environmental Policy",
	},
	fr: {
		background: "primary",
		title: "Environnement",
		content:
			"Ryerson se conforme aux règlements de l’EPA pour la gestion des déchets et attribue annuellement des fonds pour appliquer des solutions écoénergétiques dans ses entrepôts. Les programmes de recyclage de Ryerson sont pleinement fonctionnels, de la ferraille aux fournitures, et contribuent à éviter que les matériaux excédentaires ne finissent dans des décharges. As demonstrated by our Environmental Policy, we recognize the critical importance of environmental sustainability and acknowledge the responsibility we all bear toward our planet and communities.",
		imageUrl: "/EnvironmentSafety.jpg",
        fileUrl: "/static-assets/documents/pdfs/en/policies-terms-conditions/RyersonEnvironmentalPolicy.pdf",
        fileUrlLabel: "Environmental Policy",
	},
	es: {
		background: "primary",
		title: "Entorno",
		content:
			"Ryerson cumple con las regulaciones de la EPA, así como con su manejo de desechos, y destina anualmente el capital para implementar soluciones eficientes en energía dentro de sus almacenes. Los programas de reciclado de Ryerson se encuentran completamente en vías de ejecución, desde metales de deshecho hasta suministros, ayudando a retirar los materiales excedentes de los rellenos sanitarios. As demonstrated by our Environmental Policy, we recognize the critical importance of environmental sustainability and acknowledge the responsibility we all bear toward our planet and communities.",
		imageUrl: "/EnvironmentSafety.jpg",
        fileUrl: "/static-assets/documents/pdfs/en/policies-terms-conditions/RyersonEnvironmentalPolicy.pdf",
        fileUrlLabel: "Environmental Policy",
	},
};
