import React from "react";
import Layout from "@components/Shared/Templates/Layout";
import { LanguageContent } from "@components/Shared/model/LanguageContent";
import { useApplication } from "@ryerson/frontend.application";
import Landing, { LocationsPageStaticContent } from "@components/Locations/Landing";
import Meta, { MetaContent } from "@components/Shared/model/MetaHelmet";

const StaticContent: LanguageContent<LocationsPageStaticContent> = {
	en: {
		title: "Locations",
		description: `Located near you. Headquartered in Chicago, Ryerson has approximately 
            100 locations throughout North America and beyond. Search below for our closest 
            location near you.`,
		stsDescription:
			"Southern Tool Steel offers quick delivery of the metals you need, cut-to-size with no minimum order requirement. Many of our items are available for same-day shipping for immediate needs. You really can get just what you want, when you want it.",
		addMyLocation: "Add Multiple Locations",
		findALocation: "Find a Location Near",
		customLabel: "Custom Label",
		icon: "Icon",
		color: "Color",
		save: "Save",
		addAnotherLocation: "Add Another Location",
		within: "Within",
		apply: "Apply",
		pickFromList: "Pick from the list",
		useCurrentLocation: "Use Current Location",
		filterBy: "Filter By",
		clearFilters: "Clear Filters",
		processing: "Processing",
		locationType: "Location Type",
		directions: "Directions",
		edit: "Edit",
		delete: "Delete",
		viewLocationDetails: "View Location Details",
		milesAway: "miles away",
		suggestions: "Suggestions",
		found: "Found",
	},
	fr: {
		title: "Locations",
		description: `Located near you. Headquartered in Chicago, Ryerson has approximately 
            100 locations throughout North America and beyond. Search below for our closest 
            location near you.`,
		stsDescription:
			"Southern Tool Steel offers quick delivery of the metals you need, cut-to-size with no minimum order requirement. Many of our items are available for same-day shipping for immediate needs. You really can get just what you want, when you want it.",
		addMyLocation: "Add Multiple Locations",
		findALocation: "Find a Location Near",
		customLabel: "Custom Label",
		icon: "Icon",
		color: "Color",
		save: "Save",
		addAnotherLocation: "Add Another Location",
		within: "Within",
		apply: "Apply",
		pickFromList: "Pick from the list",
		useCurrentLocation: "Use Current Location",
		filterBy: "Filter By",
		clearFilters: "Clear Filters",
		processing: "Processing",
		locationType: "Location Type",
		directions: "Directions",
		edit: "Edit",
		delete: "Delete",
		viewLocationDetails: "View Location Details",
		milesAway: "miles away",
		suggestions: "Suggestions",
		found: "Found",
	},
	es: {
		title: "Locations",
		description: `Located near you. Headquartered in Chicago, Ryerson has approximately 
            100 locations throughout North America and beyond. Search below for our closest 
            location near you.`,
		stsDescription:
			"Southern Tool Steel offers quick delivery of the metals you need, cut-to-size with no minimum order requirement. Many of our items are available for same-day shipping for immediate needs. You really can get just what you want, when you want it.",
		addMyLocation: "Add Multiple Locations",
		findALocation: "Find a Location Near",
		customLabel: "Custom Label",
		icon: "Icon",
		color: "Color",
		save: "Save",
		addAnotherLocation: "Add Another Location",
		within: "Within",
		apply: "Apply",
		pickFromList: "Pick from the list",
		useCurrentLocation: "Use Current Location",
		filterBy: "Filter By",
		clearFilters: "Clear Filters",
		processing: "Processing",
		locationType: "Location Type",
		directions: "Directions",
		edit: "Edit",
		delete: "Delete",
		viewLocationDetails: "View Location Details",
		milesAway: "miles away",
		suggestions: "Suggestions",
		found: "Found",
	},
};

export default (props: any) => {
	const {
		localization: { language },
	} = useApplication();
	let data = {};
	let stateData: any = {};
	let MetaObject: LanguageContent<MetaContent> = {
		en: {
			title: "Find a Ryerson Location Near You - Ryerson",
			description: `Ryerson has approximately 100 locations in North America and beyond, providing quality metal on-demand services.`,
			url: `/locations`,
			company: true,
		},
		fr: {
			title: "Find a Ryerson Location Near You - Ryerson",
			description: `Ryerson has approximately 100 locations in North America and beyond, providing quality metal on-demand services.`,
			url: `/locations`,
			company: true,
		},
		es: {
			title: "Find a Ryerson Location Near You - Ryerson",
			description: `Ryerson has approximately 100 locations in North America and beyond, providing quality metal on-demand services.`,
			url: `/locations`,
			company: true,
		},
	};
	if (
		props &&
		props.pageResources &&
		props.pageResources.json &&
		props.pageResources.json.pageContext &&
		props.pageResources.json.pageContext.contentfulData
	) {
		data = props.pageResources.json.pageContext.contentfulData;
	}
	if (
		props &&
		props.pageResources &&
		props.pageResources.json &&
		props.pageResources.json.pageContext &&
		props.pageResources.json.pageContext.stateDetails
	) {
		stateData = props.pageResources.json.pageContext.stateDetails;
		if (props.pageResources.json.pageContext.canonical) {
			MetaObject = {
				en: {
					title: "Locations In " + stateData.label + " - Ryerson",
					description: `Located to serve you. Headquartered in Chicago, Ryerson has approximately 100 locations throughout North America and beyond.`,
					url: props.pageResources.json.pageContext.canonical,
					company: true,
				},
				fr: {
					title: "Locations In " + stateData.label + " - Ryerson",
					description: `Located to serve you. Headquartered in Chicago, Ryerson has approximately 100 locations throughout North America and beyond.`,
					url: props.pageResources.json.pageContext.canonical,
					company: true,
				},
				es: {
					title: "Locations In " + stateData.label + " - Ryerson",
					description: `Located to serve you. Headquartered in Chicago, Ryerson has approximately 100 locations throughout North America and beyond.`,
					url: props.pageResources.json.pageContext.canonical,
					company: true,
				},
			};
		}
	}
	return (
		<Layout>
			<Meta content={MetaObject[language]} />
			<Landing
				staticContent={StaticContent[language]}
				contentfulContent={data}
				stateData={stateData}
			/>
		</Layout>
	);
};
