import { IndustryContent } from "./IndustriesWeServeLanding";
import { LanguageContent } from "@components/Shared/model/LanguageContent";

export const IndustryLandingContent: LanguageContent<IndustryContent> = {
	en: {
		defaultImageUrl: "/images/industries/IndustriesWeServeUpdate.png",
		cards: [
			{
				title: "Industrial Automation",
				description:
					"Machining, framing, polishing and more—we fulfill your need for fully processed components quickly and accurately.",
				imageUrl: "/images/industries/PackagingAndProcessingUpdate.jpg",
				url: "/metal-solutions/industries/industrial-automation",
			},
			{
				title: "Data Centers",
				description:
					"From enclosure racks to cable management systems, from liquid cooling pipes to HVAC systems, the data center ecosystem is inherently metal-intensive.",
				imageUrl: "/images/industries/DatacentersLanding.jpg",
				url: "/metal-solutions/industries/data-centers",
			},
			{
				title: "Metal Fabricators & Machine Shops",
				description:
					"You need the metal—and perhaps some light fabrication—and we have the resources to meet your demanding schedule.",
				imageUrl: "/images/industries/FabMachineShop.jpg",
				url: "/metal-solutions/industries/metal-fabricators-machine-shops",
			},
			{
				title: "Heavy Equipment",
				description:
					"If it moves the Earth or harvests the land, you can rely on us to deliver production quantities of large bar and thick plate along with all the necessary processing.",
				imageUrl: "/images/industries/HeavyEquipmentUpdate2.jpg",
				url: "/metal-solutions/industries/heavy-equipment",
			},
			{
				title: "Transportation",
				description:
					"From Class A trucks and railcars to cargo trailers, we supply finished parts and solutions that will cut down your need for secondary operations.",
				imageUrl: "/images/industries/Trailers.jpg",
				url: "/metal-solutions/industries/transportation",
			},
			{
				title: "Electric Vehicles",
				description:
					"Powering the next generation of transportation, we have the inventory and processing capabilities to handle anything that comes next.",
				imageUrl: "/images/industries/EV.jpg",
				url: "/metal-solutions/industries/electric-vehicles",
			},
			{
				title: "Medical, Health, & Safety",
				description:
					"We answer the call for medical and safety product manufacturers that require product flexibility and supply chain assurance.",
				imageUrl: "/images/industries/Medical.jpg",
				url: "/metal-solutions/industries/medical-health-safety",
			},
			{
				title: "Climate",
				description:
					"Companies that manufacture products that impact climate control or air movement turn to Ryerson for customized metal solutions.",
				imageUrl: "/images/industries/Climate.jpg",
				url: "/metal-solutions/industries/climate",
			},
		],
	},
	fr: {
		defaultImageUrl: "/images/industries/IndustriesWeServeUpdate.png",
		cards: [
			{
				title: "Automatisation industrielle",
				description:
					"Qu'il s'agisse d'usinage, de cadrage, de polissage ou autres, nous satisfaisons vos besoins en vous offrant des composants traités rapidement, entièrement et avec précision.",
				imageUrl: "/images/industries/PackagingAndProcessingUpdate.jpg",
				url: "/metal-solutions/industries/industrial-automation",
			},
			{
				title: "Centres de données",
				description:
					"Qu'il s'agisse de châssis pour équipement informatique, de systèmes de gestion des câbles, de tuyaux de refroidissement par liquide ou de systèmes de chauffage, ventilation et climatisation, l'écosystème des centres de données est intrinsèquement gourmand en métal.",
				imageUrl: "/images/industries/DatacentersLanding.jpg",
				url: "/metal-solutions/industries/data-centers",
			},
			{
				title: "La fabrication de métaux et l'atelier d'usinage",
				description:
					"Vous avez besoin de métaux, et peut-être même de légers travaux de fabrication? Nous avons les ressources nécessaires pour répondre à vos échéanciers exigeants.",
				imageUrl: "/images/industries/FabMachineShop.jpg",
				url: "/metal-solutions/industries/metal-fabricators-machine-shops",
			},
			{
				title: "L'équipement lourd",
				description:
					"Que ce soit pour faire bouger la Terre ou exploiter la terre, vous pouvez compter sur nous pour vous livrer des quantités de production de grandes barres et de plaques épaisses, ainsi que tous les traitements nécessaires.",
				imageUrl: "/images/industries/HeavyEquipmentUpdate2.jpg",
				url: "/metal-solutions/industries/heavy-equipment",
			},
			{
				title: "Le transport",
				description:
					"Des camions et wagons de classe A aux remorques de fret, nous fournissons des pièces et des solutions finies qui vous permettront de réduire vos besoins en opérations secondaires.",
				imageUrl: "/images/industries/Trailers.jpg",
				url: "/metal-solutions/industries/transportation",
			},
			{
				title: "Les véhicules électriques",
				description:
					"Nous sommes prêts à accueillir la prochaine génération en matière de transport et nous avons les stocks et les capacités de traitement nécessaires pour faire face à ce que l'avenir nous réserve.",
				imageUrl: "/images/industries/EV.jpg",
				url: "/metal-solutions/industries/electric-vehicles",
			},
			{
				title: "Médecine, santé et sécurité",
				description:
					"Nous répondons à l'appel des fabricants de produits médicaux et de sécurité qui ont besoin de flexibilité au niveau des produits et d'assurance de la chaîne d'approvisionnement.",
				imageUrl: "/images/industries/Medical.jpg",
				url: "/metal-solutions/industries/medical-health-safety",
			},
			{
				title: "Climat",
				description:
					"Les entreprises qui fabriquent des produits dans le domaine de la régulation climatique ou de la circulation de l'air se tournent vers Ryerson pour des produits métallurgiques personnalisés.",
				imageUrl: "/images/industries/Climate.jpg",
				url: "/metal-solutions/industries/climate",
			},
		],
	},
	es: {
		defaultImageUrl: "/images/industries/IndustriesWeServeUpdate.png",
		cards: [
			{
				title: "Automatización industrial",
				description:
					"Mecanizado, entramado, pulido y más: satisfacemos sus necesidades de componentes totalmente procesados de manera rápida y precisa.",
				imageUrl: "/images/industries/PackagingAndProcessingUpdate.jpg",
				url: "/metal-solutions/industries/industrial-automation",
			},
			{
				title: "Centros de información",
				description:
					"Desde gabinetes para bastidor hasta sistemas de manejo de cables, desde tuberías de enfriamiento líquido hasta sistemas HVAC, el ecosistema del centro de información es fundamentalmente de metal.",
				imageUrl: "/images/industries/DatacentersLanding.jpg",
				url: "/metal-solutions/industries/data-centers",
			},
			{
				title: "Fabricantes de metales y talleres mecánicos",
				description:
					"Usted necesita el metal, y quizás alguna fabricación ligera, y nosotros tenemos los recursos para cumplir con su exigente cronograma.",
				imageUrl: "/images/industries/FabMachineShop.jpg",
				url: "/metal-solutions/industries/metal-fabricators-machine-shops",
			},
			{
				title: "Equipamiento pesado",
				description:
					"Si mueve la tierra o se usa para cosechar, puede confiar en nosotros para proporcionarle las cantidades de producción de barras grandes y placas gruesas, junto con todo el procesamiento necesario.",
				imageUrl: "/images/industries/HeavyEquipmentUpdate2.jpg",
				url: "/metal-solutions/industries/heavy-equipment",
			},
			{
				title: "Transporte",
				description:
					"Desde camiones clase A y ferrocarriles hasta remolques de carga, suministramos partes y soluciones finalizadas que reducirán su necesidad de operaciones secundarias.",
				imageUrl: "/images/industries/Trailers.jpg",
				url: "/metal-solutions/industries/transportation",
			},
			{
				title: "Vehículos eléctricos",
				description:
					"Impulsando la nueva generación de transporte, tenemos las capacidades de inventario y procesamiento necesarias para gestionar cualquier cosa que venga.",
				imageUrl: "/images/industries/EV.jpg",
				url: "/metal-solutions/industries/electric-vehicles",
			},
			{
				title: "Salud, seguridad y medio ambiente",
				description:
					"Respondemos al llamado de los fabricantes de productos médicos y de seguridad que requieren flexibilidad de productos y aseguramiento de la cadena de suministro.",
				imageUrl: "/images/industries/Medical.jpg",
				url: "/metal-solutions/industries/medical-health-safety",
			},
			{
				title: "Clima",
				description:
					"Las empresas que fabrican productos que afectan el control de la temperatura y el movimiento del aire acuden a Ryerson en busca de soluciones de metales personalizadas.",
				imageUrl: "/images/industries/Climate.jpg",
				url: "/metal-solutions/industries/climate",
			},
		],
	},
};

export const STSIndustryLandingContent: LanguageContent<IndustryContent> = {
	en: {
		defaultImageUrl: "/images/sts/industries-served/IndustriesDefault.jpg",
		cards: [
			{
				title: "Automotive/ Transportation",
				description:
					"We help put the stamp of approval on the parts used by some of the biggest automakers around the globe.",
				imageUrl: "/images/sts/industries-served/AutoUpdate.jpg",
				url: "/industries-served/automotive-transportation",
			},
			{
				title: "Technology",
				description:
					"Our steel helps produce highly precise custom electronic stampings for the unique needs of electronics makers.",
				imageUrl: "/images/sts/industries-served/TechnologyUpdate2.jpg",
				url: "/industries-served/technology",
			},
			{
				title: "Tool & Die",
				description:
					"For those that make the tools that make the tools, we know the importance of getting it right, right away.",
				imageUrl: "/images/sts/industries-served/ToolDie.jpg",
				url: "/industries-served/tool-die",
			},
			{
				title: "Heavy Equipment",
				description:
					"We meet the industrial-strength performance and precision demands needed for every part produced.",
				imageUrl: "/images/sts/industries-served/HeavyEquipmentUpdate4.jpg",
				url: "/industries-served/heavy-equipment",
			},
			{
				title: "Stamping",
				description:
					"We provide the strength, durability, and wear-resistance necessary to ensure dies and machines never fall short of expectations.",
				imageUrl: "/images/sts/industries-served/StampingUpdate2.jpg",
				url: "/industries-served/stamping",
			},
		],
	},
	fr: {
		defaultImageUrl: "/images/sts/industries-served/IndustriesDefault.jpg",
		cards: [
			{
				title: "Transport routier",
				description:
					"Nous jouons un rôle dans l’approbation des pièces utilisées par les plus grands constructeurs de véhicules automobiles sur la planète.",
				imageUrl: "/images/sts/industries-served/AutoUpdate.jpg",
				url: "/industries-served/automotive-transportation",
			},
			{
				title: "Technologie",
				description:
					"Notre acier permet de produire des estampages électroniques de grande précision sur mesure répondant aux besoins uniques des fabricants de matériel électronique.",
				imageUrl: "/images/sts/industries-served/TechnologyUpdate2.jpg",
				url: "/industries-served/technology",
			},
			{
				title: "Outillage",
				description:
					"Pour ceux qui fabriquent les outils qui fabriquent les outils, nous savons à quel point il est important de bien faire les choses, tout de suite.",
				imageUrl: "/images/sts/industries-served/ToolDie.jpg",
				url: "/industries-served/tool-die",
			},
			{
				title: "Machinerie lourde",
				description:
					"Nous aidons à répondre aux exigences de performance de résistance industrielle nécessaires pour chaque pièce produite.",
				imageUrl: "/images/sts/industries-served/HeavyEquipment.jpg",
				url: "/industries-served/heavy-equipment",
			},
			{
				title: "Estampage",
				description:
					"Nous fournissons la solidité, la durabilité et la résistance à l’usure nécessaires pour que les matrices et les machines ne soient jamais en deçà des attentes.",
				imageUrl: "/images/sts/industries-served/StampingUpdate2.jpg",
				url: "/industries-served/stamping",
			},
		],
	},
	es: {
		defaultImageUrl: "/images/sts/industries-served/IndustriesDefault.jpg",
		cards: [
			{
				title: "Automotriz/ Transporte",
				description:
					"Ayudamos a poner el sello de aprobación en las partes usadas por algunos de los más grandes fabricantes de automóviles del mundo.",
				imageUrl: "/images/sts/industries-served/AutoUpdate.jpg",
				url: "/industries-served/automotive-transportation",
			},
			{
				title: "Tecnología​",
				description:
					"Nuestro acero ayuda a producir estampados electrónicos altamente precisos para las necesidades únicas de los fabricantes de electrónicos.",
				imageUrl: "/images/sts/industries-served/TechnologyUpdate2.jpg",
				url: "/industries-served/technology",
			},
			{
				title: "Herramientas y troqueles",
				description:
					"Para aquellos que necesitan las herramientas que hacen las herramientas, conocemos la importancia de hacerlo bien y de hacerlo rápido.",
				imageUrl: "/images/sts/industries-served/ToolDie.jpg",
				url: "/industries-served/tool-die",
			},
			{
				title: "Equipamiento pesado",
				description:
					"Proporcionamos la resistencia, la durabilidad y la resistencia al desgaste necesarias para asegurar que los troqueles y las máquinas nunca estén por debajo de las expectativas. ",
				imageUrl: "/images/sts/industries-served/HeavyEquipment.jpg",
				url: "/industries-served/heavy-equipment",
			},
			{
				title: "Estampado",
				description:
					"Proporcionamos la resistencia, la durabilidad y la resistencia al desgaste necesarias para asegurar que los troqueles y las máquinas nunca estén por debajo de las expectativas. ",
				imageUrl: "/images/sts/industries-served/StampingUpdate2.jpg",
				url: "/industries-served/stamping",
			},
		],
	},
};
