import React from "react";
import Layout from "@components/Shared/Templates/Layout";
import Locations, { ContentfulNode, LocationStaticContent } from "@components/Locations/Locations";
import { LanguageContent } from "@components/Shared/model/LanguageContent";
import { useApplication } from "@ryerson/frontend.application";
import MetaHelmet, { MetaContent } from "@components/Shared/model/MetaHelmet";

const StaticContent: LanguageContent<LocationStaticContent> = {
	en: {
		localNumberLabel: "Local Number:",
		call: "Call",
		afterHoursService: "for after-hours service",
		getDirections: "Get Directions",
		contactThisLocation: "Contact This Location",
		sunday: "Sun",
		monday: "Mon",
		tuesday: "Tue",
		wednesday: "Wed",
		thursday: "Thu",
		friday: "Fri",
		saturday: "Sat",
		managementTeam: "Management Team",
		localSolutionsCapabilities: "Local Solutions/Capabilities",
		firstName: "First Name",
		lastName: "Last Name",
		email: "E-Mail",
		phoneNumber: "Phone Number",
		zip: "Zip Code",
		company: "Company",
		topicLabel: "Topic",
		blankTopicVerbiage:
			"How can we help? Let us know what you need. Don't forget you can also live chat with a representative.",
		careersTopicVerbiage:
			"If you have a question on a specific role, please include the job title and location you are interested. Want to see open positions? ",
		onlineStoreTopicVerbiage:
			"If you are interested in an online account, please click the sign up button at the top of the page. Don't forget you can also live chat with a representative.",
		productQuestionTopicVerbiage: "How can we help? Let us know what you need.",
		quoteRequestTopicVerbiage:
			"Please include your quantity, quantity unit of measure, and item(s) requested.",
		requestSalesContactTopicVerbiage: "How can we help? Let us know what you need.",
		mediaContactTopicVerbiage: "",
		otherTopicVerbiage:
			"How can we help? Let us know what you need. Don't forget you can also live chat with a representative.",
		message: "Message",
		sendMessage: "Send Message",
		backToOtherLocations: "Back to Other Locations",
	},
	fr: {
		localNumberLabel: "Local Number:",
		call: "Call",
		afterHoursService: "for after-hours service",
		getDirections: "Get Directions",
		contactThisLocation: "Contact This Location",
		sunday: "Sun",
		monday: "Mon",
		tuesday: "Tue",
		wednesday: "Wed",
		thursday: "Thu",
		friday: "Fri",
		saturday: "Sat",
		managementTeam: "Management Team",
		localSolutionsCapabilities: "Local Solutions/Capabilities",
		firstName: "First Name",
		lastName: "Last Name",
		email: "E-Mail",
		phoneNumber: "Phone Number",
		zip: "Zip Code",
		company: "Company",
		topicLabel: "Topic",
		blankTopicVerbiage:
			"Comment pouvons nous aider? Faites-nous savoir ce dont vous avez besoin. N'oubliez pas que vous pouvez également discuter en direct avec un représentant.",
		careersTopicVerbiage:
			"Si vous avez une question sur un rôle spécifique, veuillez inclure le titre du poste et le lieu qui vous intéresse. Vous voulez voir les postes vacants ? Cliquez ici.",
		onlineStoreTopicVerbiage:
			"Si vous êtes intéressé par un compte en ligne, veuillez cliquer sur le bouton d'inscription en haut de la page. N'oubliez pas que vous pouvez également discuter en direct avec un représentant.",
		productQuestionTopicVerbiage:
			"Comment pouvons nous aider? Faites-nous savoir ce dont vous avez besoin.",
		quoteRequestTopicVerbiage:
			"Veuillez inclure votre quantité, l'unité de quantité de mesure et le ou les articles demandés.",
		requestSalesContactTopicVerbiage:
			"Comment pouvons nous aider? Faites-nous savoir ce dont vous avez besoin.",
		mediaContactTopicVerbiage: "",
		otherTopicVerbiage:
			"Comment pouvons nous aider? Faites-nous savoir ce dont vous avez besoin. N'oubliez pas que vous pouvez également discuter en direct avec un représentant.",
		message: "Message",
		sendMessage: "Send Message",
		backToOtherLocations: "Back to Other Locations",
	},
	es: {
		localNumberLabel: "Local Number:",
		call: "Call",
		afterHoursService: "for after-hours service",
		getDirections: "Get Directions",
		contactThisLocation: "Contact This Location",
		sunday: "Sun",
		monday: "Mon",
		tuesday: "Tue",
		wednesday: "Wed",
		thursday: "Thu",
		friday: "Fri",
		saturday: "Sat",
		managementTeam: "Management Team",
		localSolutionsCapabilities: "Local Solutions/Capabilities",
		firstName: "First Name",
		lastName: "Last Name",
		email: "E-Mail",
		phoneNumber: "Phone Number",
		zip: "Zip Code",
		company: "Company",
		topicLabel: "Topic",
		blankTopicVerbiage:
			"¿Cómo podemos ayudar? Háganos saber lo que necesita. No olvide que también puede chatear en vivo con un representante.",
		careersTopicVerbiage:
			"Si tiene una pregunta sobre un puesto específico, incluya el puesto y la ubicación que le interesan. ¿Quieres ver las posiciones abiertas? Haga clic aquí.",
		onlineStoreTopicVerbiage:
			"Si está interesado en una cuenta en línea, haga clic en el botón de registro en la parte superior de la página. No olvide que también puede chatear en vivo con un representante.",
		productQuestionTopicVerbiage: "¿Cómo podemos ayudar? Háganos saber lo que necesita.",
		quoteRequestTopicVerbiage:
			"Incluya la cantidad, la unidad de medida de la cantidad y los artículos solicitados.",
		requestSalesContactTopicVerbiage: "¿Cómo podemos ayudar? Háganos saber lo que necesita.",
		mediaContactTopicVerbiage: "",
		otherTopicVerbiage:
			"¿Cómo podemos ayudar? Háganos saber lo que necesita. No olvide que también puede chatear en vivo con un representante.",
		message: "Message",
		sendMessage: "Send Message",
		backToOtherLocations: "Back to Other Locations",
	},
};

export default (props: any) => {
	const {
		localization: { language },
	} = useApplication();
	if (
		props &&
		props.pageResources &&
		props.pageResources.json &&
		props.pageResources.json.pageContext &&
		props.pageResources.json.pageContext.contentfulData
	) {
		let Meta: LanguageContent<MetaContent> = {
			en: {
				title: "",
				description: "",
				url: "",
				company: false,
			},
			fr: {
				title: "",
				description: "",
				url: "",
				company: false,
			},
			es: {
				title: "",
				description: "",
				url: "",
				company: false,
			},
		};
		const data = props.pageResources.json.pageContext.contentfulData as ContentfulNode[];
		let structuredContent: any = {};
		data.forEach((d: any) => {
			let canonical = "/locations";
			if (d.node.country === "Mexico") {
				canonical += "/" + d.node.slug;
			} else {
				canonical +=
					"/" +
					d.node.country.toLowerCase().replace(" ", "-") +
					"/" +
					d.node.state.toLowerCase().replace(" ", "-") +
					"/" +
					d.node.slug;
			}
			let obj = {
				title: d.node.seoTitle,
				description: d.node.seoDescription,
				imageUrl: d.node.locationPicture.file.url,
				url: canonical,
				company: false,
			};

			if (d.node.node_locale.split("-").shift() === "en") {
				Meta.en = obj;
				structuredContent = {
					"@context": "https://schema.org",
					"@type": "LocalBusiness",
					name: d.node.locationName,
					image: d.node.locationPicture.file.url,
					url: "https://ryerson.com" + canonical,
					telephone: d.node.phoneNumber,
					address: {
						"@type": "PostalAddress",
						streetAddress: d.node.streetAddress,
						addressLocality: d.node.city,
						addressRegion: d.node.state,
						postalCode: d.node.zipCode,
						addressCountry: d.node.country,
					},
					geo: {
						"@type": "GeoCoordinates",
						latitude: d.node.location.lat,
						longitude: d.node.location.lon,
					},
				};
				if (d.node.locationHours) {
					structuredContent.openingHoursSpecification = [];
					if (d.node.locationHours.sundayOpen && d.node.locationHours.sundayClose) {
						structuredContent.openingHoursSpecification.push({
							"@type": "OpeningHoursSpecification",
							dayOfWeek: ["Sunday"],
							opens: d.node.locationHours.sundayOpen,
							closes: d.node.locationHours.sundayClose,
						});
					}
					if (d.node.locationHours.mondayOpen && d.node.locationHours.mondayClose) {
						structuredContent.openingHoursSpecification.push({
							"@type": "OpeningHoursSpecification",
							dayOfWeek: ["Monday"],
							opens: d.node.locationHours.mondayOpen,
							closes: d.node.locationHours.mondayClose,
						});
					}
					if (d.node.locationHours.tuesdayOpen && d.node.locationHours.tuesdayClose) {
						structuredContent.openingHoursSpecification.push({
							"@type": "OpeningHoursSpecification",
							dayOfWeek: ["Tuesday"],
							opens: d.node.locationHours.tuesdayOpen,
							closes: d.node.locationHours.tuesdayClose,
						});
					}
					if (d.node.locationHours.wednesdayOpen && d.node.locationHours.wednesdayClose) {
						structuredContent.openingHoursSpecification.push({
							"@type": "OpeningHoursSpecification",
							dayOfWeek: ["Wednesday"],
							opens: d.node.locationHours.wednesdayOpen,
							closes: d.node.locationHours.wednesdayClose,
						});
					}
					if (d.node.locationHours.thursdayOpen && d.node.locationHours.thursdayClose) {
						structuredContent.openingHoursSpecification.push({
							"@type": "OpeningHoursSpecification",
							dayOfWeek: ["Thursday"],
							opens: d.node.locationHours.thursdayOpen,
							closes: d.node.locationHours.thursdayClose,
						});
					}
					if (d.node.locationHours.fridayOpen && d.node.locationHours.fridayClose) {
						structuredContent.openingHoursSpecification.push({
							"@type": "OpeningHoursSpecification",
							dayOfWeek: ["Friday"],
							opens: d.node.locationHours.fridayOpen,
							closes: d.node.locationHours.fridayClose,
						});
					}
					if (d.node.locationHours.saturdayOpen && d.node.locationHours.saturdayClose) {
						structuredContent.openingHoursSpecification.push({
							"@type": "OpeningHoursSpecification",
							dayOfWeek: ["Saturday"],
							opens: d.node.locationHours.saturdayOpen,
							closes: d.node.locationHours.saturdayClose,
						});
					}
				}
			} else if (d.node.node_locale.split("-").shift() === "fr") {
				Meta.fr = obj;
			} else {
				Meta.es = obj;
			}
		});

		return (
			<Layout>
				<MetaHelmet content={Meta[language]}>
					<script type="application/ld+json">{JSON.stringify(structuredContent)}</script>
				</MetaHelmet>
				<Locations contentfulContent={data} content={StaticContent[language]} />
			</Layout>
		);
	} else {
		return <></>;
	}
};
