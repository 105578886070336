import React from "react";
import { StaticQuery, graphql } from "gatsby";
import { HeroImage } from "@components/BlockZone/Blocks";
import ExpressSearch from "@components/HomePage/ExpressSearch/ExpressSearch";
import { STSContent } from "@components/Shared/RyersonDifference/STSContent";
import RyersonDifferenceSection from "@components/Shared/RyersonDifference/RyersonDifferenceSection";
import { useTheme } from "@ryerson/frontend.theme";
import styled from "@emotion/styled";
import MetalInsightsAndResourcesRefactor, {
	defaultStaticContent as MetalInsightsContent,
} from "@components/Shared/MetalInsights/MetalInsightsAndResourcesRefactor";
import { AllSections, Sections } from "@components/Shared/AllSections/AllSections";
import IndustriesWeServe from "@components/Shared/IndustriesWeServe/IndustriesWeServe";
import { DefaultIndustriesWeServeContent } from "@components/Shared/IndustriesWeServe/Content";
import MetalExperts from "@components/Shared/YourMetalExperts/YourMetalExperts";
import { YourStaticContent } from "@components/Shared/YourMetalExperts/Content";
import { Media } from "@ryerson/frontend.layout";
import WhatOurCustomers from "@components/Shared/WhatOurCustomers/WhatOurCustomersRefactor";
import { WhatOurContentSays } from "@components/Shared/WhatOurCustomers/RefactorContent";
import { STSWhatOurContentSays } from "@components/Shared/WhatOurCustomers/STSContent";
import { HeroContent } from "@components/BlockZone/Blocks/HeroImage/Content";
import { RyersonContent } from "@components/Shared/RyersonDifference/Content2";
import SolutionsWeOffer from "@components/Solutions/ParallaxSolutionsWeOffer/SolutionsWeOffer";
import { SolutionsContent } from "@components/Solutions/ParallaxSolutionsWeOffer/Content";
import OurCapabilities from "@components/Shared/ParallaxOurCapabilities/OurCapabilities";
import { OurCapabilitiesContent } from "@components/Shared/ParallaxOurCapabilities/Content";
import { STSOurCapabilitiesContent } from "@components/Shared/ParallaxOurCapabilities/STSContent";
import { useApplication } from "@ryerson/frontend.application";
import ContactUsRefactor from "@components/Shared/ContactUsRefactor/ContactUs";
import { DefaultContactUsContent } from "@components/Shared/ContactUsRefactor/Content";
import { STSHeroContent } from "@components/BlockZone/Blocks/HeroImage/STSContent";
import { HeroImageProps } from "@components/BlockZone/Blocks/HeroImage";
import { STSSolutionsContent } from "@components/Solutions/ParallaxSolutionsWeOffer/STSContent";
import { STSIndustriesWeServeContent } from "@components/Shared/IndustriesWeServe/STSContent";
import BelowSearchAlert from "@components/HomePage/Alerts/BelowSearchAlert";
import { LanguageContent } from "@components/Shared/model/LanguageContent";
import Meta, { MetaContent } from "@components/Shared/model/MetaHelmet";
import MyAccountDashboardMenu from "@components/Shared/MyAccountDashboardMenu";

const MetaObject: LanguageContent<MetaContent> = {
	en: {
		title: "Online Metals Supplier - Metal Processing & Distribution - Ryerson",
		description: `Ryerson is an online metal supplier, metal processor and distributor, offering more than 65,000 varieties of stainless, aluminum, carbon and alloys in all shapes and sizes.`,
		url: `/`,
		company: true,
	},
	fr: {
		title: "Online Metals Supplier - Metal Processing & Distribution - Ryerson",
		description: `Ryerson is an online metal supplier, metal processor and distributor, offering more than 65,000 varieties of stainless, aluminum, carbon and alloys in all shapes and sizes.`,
		url: `/`,
		company: true,
	},
	es: {
		title: "Online Metals Supplier - Metal Processing & Distribution - Ryerson",
		description: `Ryerson is an online metal supplier, metal processor and distributor, offering more than 65,000 varieties of stainless, aluminum, carbon and alloys in all shapes and sizes.`,
		url: `/`,
		company: true,
	},
};

const Homepage = () => (
	<StaticQuery
		query={graphql`
			query homePage {
				contentfulHomepage {
					slug
					title
					contentBlocks {
						__typename
						... on Node {
							... on ContentfulHeadlineWithDescription {
								description {
									description
								}
							}
							... on ContentfulFullWidthImage {
								image {
									file {
										url
									}
								}
							}
							... on ContentfulInfoWithImage {
								image {
									file {
										url
									}
								}
								description {
									description
								}
								imagePosition
							}
							... on ContentfulTextInfo {
								leftColumnText {
									leftColumnText
								}
								rightColumnText {
									rightColumnText
								}
							}
							... on ContentfulHeroImage {
								image {
									file {
										details {
											image {
												height
												width
											}
										}
										url
									}
								}
								largeText
								smallText
							}
						}
					}
				}
			}
		`}
		render={(data) => {
			const { theme } = useTheme();
			const Container = styled.div`
				font-family: ${theme.typography.fontFamilies.primary};
				background-color: ${theme.colors.primary.white};
			`;
			const {
				localization: { language },
			} = useApplication();

			// const sections: Sections[] = [
			// 	{ title: "What Our Customers Say", cmp: <WhatOurCustomers /> },
			// 	{
			// 		title: RyersonContent[language].title1 + " " + RyersonContent[language].title2,
			// 		cmp: <RyersonDifferenceSection />,
			// 	},
			// 	{
			// 		title: SolutionsContent[language].title,
			// 		cmp: <SolutionsWeOffer background="tertiary" />,
			// 	},
			// 	{ title: OurCapabilitiesContent[language].title, cmp: <OurCapabilities /> },
			// 	{ title: "Your Local Metal Experts", cmp: <MetalExperts /> },
			// 	{
			// 		title: DefaultIndustriesWeServeContent[language].title ?? "",
			// 		cmp: <IndustriesWeServe subPage={false} />,
			// 	},
			// 	{
			// 		title: DefaultContactUsContent[language].title,
			// 		cmp: <ContactUsRefactor oneButtonVariant={false} />,
			// 		hide: true,
			// 	},
			// 	{
			// 		title: "Metal Insights & Resources",
			// 		cmp: <MetalInsightsAndResources {...MetalInsights} />,
			// 	},
			// ];

			const sections: Sections[] = [];
			let HeroImageContent: HeroImageProps = HeroContent[language];
			if (process.env.GATSBY_COMPANY === "ryerson") {
				sections.push(
					{ title: WhatOurContentSays[language].title, cmp: <WhatOurCustomers /> },
					{
						title:
							RyersonContent[language].title1 + " " + RyersonContent[language].title2,
						cmp: <RyersonDifferenceSection />,
					},
					{
						title: SolutionsContent[language].title,
						cmp: <SolutionsWeOffer background="tertiary" />,
					},
					{ title: OurCapabilitiesContent[language].title, cmp: <OurCapabilities /> },
					{ title: YourStaticContent[language].navLabel, cmp: <MetalExperts /> },
					{
						title: DefaultIndustriesWeServeContent[language].title ?? "",
						cmp: <IndustriesWeServe subPage={false} />,
					},
					{
						title: DefaultContactUsContent[language].title,
						cmp: <ContactUsRefactor oneButtonVariant={false} />,
						hide: true,
					},
					{
						title:
							MetalInsightsContent[language].title1 +
							" " +
							MetalInsightsContent[language].titleSeparator +
							" " +
							MetalInsightsContent[language].title2,
						cmp: <MetalInsightsAndResourcesRefactor />,
					}
				);
			}

			if (process.env.GATSBY_COMPANY === "southernToolSteel") {
				HeroImageContent = STSHeroContent[language];
				sections.push(
					{ title: STSWhatOurContentSays[language].title, cmp: <WhatOurCustomers /> },
					{
						title: STSContent[language].title1 + " " + STSContent[language].title2,
						cmp: <RyersonDifferenceSection />,
					},
					{
						title: SolutionsContent[language].title,
						cmp: (
							<SolutionsWeOffer
								background="secondary"
								content={STSSolutionsContent[language]}
							/>
						),
					},
					{
						title: STSOurCapabilitiesContent[language].title,
						cmp: <OurCapabilities content={STSOurCapabilitiesContent[language]} />,
					},
					{ title: YourStaticContent[language].navLabel, cmp: <MetalExperts /> },
					{
						title: STSIndustriesWeServeContent[language].title ?? "",
						cmp: (
							<IndustriesWeServe
								content={STSIndustriesWeServeContent[language]}
								subPage={false}
							/>
						),
					},
					{
						title: DefaultContactUsContent[language].title,
						cmp: <ContactUsRefactor oneButtonVariant={false} />,
						hide: true,
					}
				);
			}

			return (
				<Container>
					<Meta content={MetaObject[language]} />
					<HeroImage {...HeroImageContent} />
					<span id="expressSearchSection">
						<ExpressSearch />
					</span>
					<Media lessThan="lg">
						<MyAccountDashboardMenu isMobile={true} isHeroPage={true} />
					</Media>
					<BelowSearchAlert />
					<AllSections sections={sections} />
					<Media lessThan="lg">
						<ContactUsRefactor oneButtonVariant={false} background="secondary" />
					</Media>
				</Container>
			);
		}}
	/>
);

export default Homepage;
