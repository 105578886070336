import React from "react";
import { Media } from "@ryerson/frontend.layout";
import ResilientSupplyChainMobile from "./Mobile/ResilientSupplyChainMobile";
import ResilientSupplyChainDesktop from "./Desktop/ResilientSupplyChainDesktop";
import { CardComponentProps } from "@components/Shared/model/CardComponent";

export interface BulletPoint {
	subTitle: string;
	context: string;
}

export interface ResilientSupplyChainProps {
	content: BulletPoint[];
	background?: "primary" | "secondary" | "tertiary";
	sectionTitle: string;
	title1?: string;
	title2?: string;
	description1: string;
	description2: string;
	questionsContext: string;
	question1: string;
	answer1?: string;
	answerComment?: string;
	question2?: string;
	buttonLabel?: string;
	imageUrl: string;
	cardItems?: CardComponentProps[];
}

interface ResilientSupplyChainSection {
	content: ResilientSupplyChainProps;
}

const ResilientSupplyChain: React.FC<ResilientSupplyChainSection> = ({ content }) => {
	return (
		<>
			<Media lessThan="lg">
				<ResilientSupplyChainMobile {...content} />
			</Media>
			<Media greaterThanOrEqual="lg">
				<ResilientSupplyChainDesktop {...content} />
			</Media>
		</>
	);
};

export default ResilientSupplyChain;
