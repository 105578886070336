import { LanguageContent } from "../model/LanguageContent";
import { SubscribeContentProps } from "./StayTuned";

export const SubscribeContent: LanguageContent<SubscribeContentProps> = {
	en: {
		title: "Stay Tuned",
		description: `Subscribe to receive the latest updates about metal and industry trends.`,
		actionLabel: "Subscribe",
		inputPlaceholder: "Your email",
		successMessage:
			"Thank you! Be on the lookout for  important market updates from Ryerson in your inbox.",
		imageUrl: "/images/metal-resources/tutorials/StayTuned.png",
		errorMessage: "Something Went Wrong",
	},
	fr: {
		title: "Restez à l'écoute",
		description:
			"Abonnez-vous pour recevoir les dernières mises à jour sur les tendances du métal et de l'industrie.",
		actionLabel: "S'abonner",
		inputPlaceholder: "Votre e-mail",
		successMessage:
			"Merci! Soyez à l'affût des mises à jour importantes du marché de Ryerson dans votre boîte de réception.",
		imageUrl: "/images/metal-resources/tutorials/StayTuned.png",
		errorMessage: "Quelque chose s'est mal passé",
	},
	es: {
		title: "Manténganse al tanto",
		description:
			"Suscríbase para recibir las últimas actualizaciones sobre las tendencias del metal y la industria.",
		actionLabel: "Suscribir",
		inputPlaceholder: "Tu correo electrónico",
		successMessage:
			"¡Gracias! Esté atento a las actualizaciones importantes del mercado de Ryerson en su bandeja de entrada.",
		imageUrl: "/images/metal-resources/tutorials/StayTuned.png",
		errorMessage: "Quelque chose s'est mal passé",
	},
};
