import React from "react";
import { Media } from "@ryerson/frontend.layout";
import MetalMarketIntelligenceDesktop from "./MetalMarketIntelligenceDesktop/MetalMarketIntelligenceDesktop";
import MetalMarketIntelligenceMobile from "./MetalMarketIIntelligenceMobile/MetalMarketIntelligenceMobile";
import { BlogCategory } from "@components/Blog/Blog";

export type MetalMarketIntelligenceProps = {
	title: string;
	imageUrl: string;
	mobileImageUrl: string;
	mobileImageText: string;
	description: string;
	actionText: string[];
	actionLink: string;
	metalMarketIntelligenceTypes: BlogCategory[];
};

const MetalMarketIntelligence: React.FC<MetalMarketIntelligenceProps> = (props) => {
	return (
		<>
			<Media greaterThanOrEqual="lg">
				<MetalMarketIntelligenceDesktop {...props} />
			</Media>
			<Media lessThan="lg">
				<MetalMarketIntelligenceMobile {...props} />
			</Media>
		</>
	);
};

export default MetalMarketIntelligence;
