import { StaticPageContent } from "@components/Shared/model/StaticPageContent";
import { LanguageContent } from "@components/Shared/model/LanguageContent";
import { ValueAddedBenefitsProperties } from "@components/Industries/ValueAddedBenefits/ValueAddedBenefits";
import { HeroProps } from "@components/Industries/Hero/Hero";
import { RelatedSolutionsCapabilitiesAndProductsProperties } from "@components/Industries/RelatedSolutionsCapabilitiesAndProducts/RelatedSolutionsCapabilitiesAndProducts";
import { MetaContent } from "@components/Shared/model/MetaHelmet";
import { SubPageContent } from "@components/Industries/SubPage/SubPage";

export const MetaObject: LanguageContent<MetaContent> = {
	en: {
		title: "Metal Supply for The Transportation Industry - Ryerson",
		description:
			"From A class trucks and railcars to cargo trailers to electric vehicles, Ryerson supplies the metal for the transportation industry.",
		url: `/metal-solutions/industries/transportation`,
		imageUrl: "/TransportationDesktop.jpg",
		company: true,
	},
	fr: {
		title: "Metal Supply for The Transportation Industry - Ryerson",
		description:
			"From A class trucks and railcars to cargo trailers to electric vehicles, Ryerson supplies the metal for the transportation industry.",
		url: `/metal-solutions/industries/transportation`,
		imageUrl: "/TransportationDesktop.jpg",
		company: true,
	},
	es: {
		title: "Metal Supply for The Transportation Industry - Ryerson",
		description:
			"From A class trucks and railcars to cargo trailers to electric vehicles, Ryerson supplies the metal for the transportation industry.",
		url: `/metal-solutions/industries/transportation`,
		imageUrl: "/TransportationDesktop.jpg",
		company: true,
	},
};

export const HeroContent: LanguageContent<HeroProps> = {
	en: {
		heroTitle: "Transportation",
		heroDescription: [
			"Supply continuity has become the road less traveled for companies today, unfortunately. But it doesn't have to be that way—nor is it for many in the transportation industry.",
			"From Class 8 trucks and railcars to cargo trailers to electric vehicles, companies across all sectors of transportation rely on Ryerson to supply the material they need, how they need it and when they need it.",
			"From the metal grades you need to the processing required, the approach that our market professionals take is designed with your specific needs in mind. Consider it the differentiator between just a solution and a customized metal solution from Ryerson.",
		],
		buttonLabel: "Contact a Professional",
		hasVideo: false,
		heroImageUrl: "/TransportationDesktop.jpg",
		heroVideoId: "",
		isWatermarkHero: true,
		watermarkUrl: "/images/industries/TransportationWatermark.jpg",
	},
	fr: {
		heroTitle: "Le transport",
		heroDescription: [
			"La continuité du processus d'approvisionnement n'est malheureusement pas chose aisée pour les entreprises à l'heure actuelle. Mais il n'est pas nécessaire qu'il en soit ainsi et c'est également vrai pour de nombreuses entreprises dans le secteur des transports.",
			"Que ce soit pour des camions de catégorie A, des autorails, des remorques à marchandises ou des véhicules électriques, les entreprises dans tous les domaines du secteur des transports comptent sur Ryerson pour leur fournir les matériaux dont elles ont besoin, de la façon dont elles en ont besoin et au bon moment.",
			"Des qualités de métal dont vous avez besoin au traitement requis, l'approche adoptée par nos experts du marché est conçue en fonction de vos besoins particuliers. C'est ce qui distingue une simple solution d'une solution métallurgique personnalisée de Ryerson.",
		],
		buttonLabel: "Contactez un expert",
		hasVideo: false,
		heroImageUrl: "/TransportationDesktop.jpg",
		heroVideoId: "",
		isWatermarkHero: true,
		watermarkUrl: "/images/industries/TransportationWatermark.jpg",
	},
	es: {
		heroTitle: "Transporte",
		heroDescription: [
			"Por desgracia, la continuidad del suministro se ha convertido en el desafío más importante en la actualidad. Pero no tiene por qué ser así; de hecho, no es así para muchas empresas de la industria del transporte.",
			"Desde camiones de clase A y ferrocarriles hasta camiones de carga y vehículos eléctricos, las empresas de todos los sectores del transporte confían en Ryerson para que les suministren el material que necesitan, sin importar cómo y cuándo lo necesitan.",
			"El enfoque de nuestros expertos de mercado está diseñado teniendo en cuenta sus necesidades específicas, desde los grados de metal que necesita hasta el procesamiento necesario. Y esto marca la diferencia entre una solución sin más y una solución de metal personalizada de Ryerson.",
		],
		buttonLabel: "Comuníquese con un experto",
		hasVideo: false,
		heroImageUrl: "/TransportationDesktop.jpg",
		heroVideoId: "",
		isWatermarkHero: true,
		watermarkUrl: "/images/industries/TransportationWatermark.jpg",
	},
};

export const SubPages: LanguageContent<SubPageContent> = {
	en: {
		cards: [
			{
				title: "Trailer Manufacturing",
				description:
					"We are attuned to the changing demands being placed on trailer manufacturers. From large-scale production to specialized components or cosmetic applications, we supply finished parts and customized metal solutions that reduce the need for secondary operations.",
				imageUrl: "/images/industries/transportation/TrailerManufacturingLink.jpg",
				url: "/metal-solutions/industries/transportation/trailer-manufacturing",
			},
			{
				title: "Electric Vehicles",
				description:
					"Powering the next generation of transportation, we have the inventory and processing capabilities to handle anything that comes next.",
				imageUrl: "/images/industries/transportation/ElectricVehiclesLink.jpg",
				url: "/metal-solutions/industries/electric-vehicles/",
			},
		],
	},
	fr: {
		cards: [
			{
				title: "Fabrication de remorques",
				description:
					"Nous sommes conscients des demandes changeantes imposées aux fabricants de remorques. De la production à grande échelle aux pièces spécialisée ou applications esthétiques, nous fournissons des pièces finies et des produits métallurgiques personnalisés qui réduisent la nécessité d'opérations secondaires.",
				imageUrl: "/images/industries/transportation/TrailerManufacturingLink.jpg",
				url: "/metal-solutions/industries/transportation/trailer-manufacturing",
			},
			{
				title: "Les véhicules électriques",
				description:
					"Nous sommes prêts à accueillir la prochaine génération de transport et nous avons les stocks et les capacités de traitement nécessaires pour faire face à ce que l'avenir nous réserve.",
				imageUrl: "/images/industries/transportation/ElectricVehiclesLink.jpg",
				url: "/metal-solutions/industries/electric-vehicles/",
			},
		],
	},
	es: {
		cards: [
			{
				title: "Fabricación de remolques",
				description:
					"Estamos acostumbrados a los cambios en la demanda que sufren los fabricantes de remolques. Desde producción a gran escala hasta componentes especializados o aplicaciones superficiales: suministramos piezas finalizadas y soluciones de metal personalizadas que reducen la necesidad de contar con operaciones secundarias.",
				imageUrl: "/images/industries/transportation/TrailerManufacturingLink.jpg",
				url: "/metal-solutions/industries/transportation/trailer-manufacturing",
			},
			{
				title: "Vehículos eléctricos",
				description:
					"Impulsando la nueva generación de transporte, tenemos las capacidades de inventario y procesamiento necesarias para gestionar cualquier cosa que venga.",
				imageUrl: "/images/industries/transportation/ElectricVehiclesLink.jpg",
				url: "/metal-solutions/industries/electric-vehicles/",
			},
		],
	},
};

export const ValueAddedBenefitsContent: LanguageContent<ValueAddedBenefitsProperties> = {
	en: {
		title: "Value-Added Benefits",
		mainDescription: `With one of the largest trailer fleets in our own industry, we know the transportation business as both a supplier and as a customer. Our knowledge gives us unique insights that can help you grow—efficiently and productively.`,
		buttonLabel: "Let's Talk!",
		secondaryContent: [
			{
				title: "Painted Aluminum",
				description: `Our strategic partnership with producing mills help to ensure continuity of supply of high-quality painted aluminum, which makes all the difference amid the rising demand of the product within this market.`,
				imageUrl: "/images/industries/transportation/PaintedAluminum.jpg",
				showImageOnDesktop: false,
				showImageOnMobile: true,
			},
			{
				title: "Cut and Formed Plate",
				description: `Our world-class plate cutting technology and equipment and dedicated facilities translate into fast and reliable turnaround for anything from simple cut pieces to more complex fabrications including beveling, machining, blasting, and forming.`,
				imageUrl: "/images/industries/transportation/CutFormedPlateUpdate.jpg",
				showImageOnDesktop: true,
				showImageOnMobile: true,
			},
		],
	},
	fr: {
		title: "Avantages à valeur ajoutée",
		mainDescription: `Nous possédons l'un des plus grands parcs de remorques de notre secteur. Nous connaissons donc le secteur des transports tant du point de vue du fournisseur que du client. Grâce à nos connaissances, nous avons une perspective unique qui peut vous aider à croître de manière efficace et productive.`,
		buttonLabel: "Discutons",
		secondaryContent: [
			{
				title: "Aluminium peint",
				description: `Nos partenariats stratégiques avec des usines de production nous aident à assurer une continuité de l'approvisionnement en aluminium peint de haute qualité, ce qui fait toute la différence face à la demande croissante du marché.`,
				imageUrl: "/images/industries/transportation/PaintedAluminum.jpg",
				showImageOnDesktop: false,
				showImageOnMobile: true,
			},
			{
				title: "Plaques coupées ou formées",
				description: `Notre technologie et notre équipement de calibre mondial de coupe ainsi que nos installations dédiées permettent de garantir un délai d'exécution rapide et fiable, tant pour les simples pièces coupées que pour les opérations plus complexes comme le chanfreinage, l'usinage, le sablage et le formage.`,
				imageUrl: "/images/industries/transportation/CutFormedPlateUpdate.jpg",
				showImageOnDesktop: true,
				showImageOnMobile: true,
			},
		],
	},
	es: {
		title: "Ventajas de valor añadido",
		mainDescription: `Contamos con una de las flotas de remolques más grandes de la industria, así que conocemos el negocio del transporte como proveedores y como clientes. Nuestros conocimientos nos proporcionan datos únicos que pueden ayudarle a prosperar en su negocio de una forma eficaz y productiva.`,
		buttonLabel: "Hablemos",
		secondaryContent: [
			{
				title: "Aluminio pintado",
				description: `Nuestra asociación estratégica con plantas siderúrgicas nos ayuda a garantizar la continuidad del suministro de aluminio pintado de alta calidad, y es esto lo que marca la diferencia en un mercado en el que la demanda de este producto no para de aumentar.`,
				imageUrl: "/images/industries/transportation/PaintedAluminum.jpg",
				showImageOnDesktop: false,
				showImageOnMobile: true,
			},
			{
				title: "Corte y formado de placas",
				description: `Nuestros equipos y tecnología de corte de placas de primera categoría, así como nuestras instalaciones especializadas, se traducen en plazos de entrega rápidos y fiables de cualquier producto, desde sencillas piezas cortadas hasta fabricaciones más complejas, como biselado, trabajo con máquina, deformación y formado.`,
				imageUrl: "/images/industries/transportation/CutFormedPlateUpdate.jpg",
				showImageOnDesktop: true,
				showImageOnMobile: true,
			},
		],
	},
};

export const RelatedSolutionsAndCapabilitiesContent: LanguageContent<RelatedSolutionsCapabilitiesAndProductsProperties> =
	{
		en: {
			title: "Related Solutions, Capabilities, and Products",
			mainDescription:
				"We can help source the material you need and process parts in a cost-effective manner.",
			secondaryContent: [
				{
					title: "Solutions & Capabilities",
					jumpToLabel: "More Metal Solutions",
					jumpToLink: "/metal-solutions/",
					bullets: [
						{
							title: "Tube Laser",
							iconType: "capabilities-tube-laser",
							link: "/metal-solutions/capabilities/bar-tube-structural-processing/tube-laser/",
						},
						{
							title: "Plate Processing",
							link: "/metal-solutions/capabilities/plate-processing/",
							iconType: "solution-fabrication",
						},
						{
							title: "Aluminum Extrusion",
							link: "/metal-solutions/solutions/custom-aluminum-extrusions/",
							iconType: "solution-custom-aluminum-extrusions",
						},
					],
				},
				{
					title: "Products",
					jumpToLabel: "More Metal Products",
					jumpToLink: "/store",
					bullets: [
						{
							title: "Aluminum Sheet",
							imageUrl: "/static-assets/images/renderings/AluminumSheet.png",
							link: "/aluminum/aluminum-sheet/",
						},
						{
							title: "Tread plate",
							imageUrl:
								"/static-assets/images/renderings/AluminumTreadplateDiamond.png",
							link: "/aluminum/aluminum-diamond-tread-plate-expanded-metal-flooring",
						},
						{
							title: "Seamless roof coil",
							imageUrl: "/static-assets/images/renderings/AluminumCoil.png",
							link: "/aluminum/aluminum-sheet",
						},
					],
				},
			],
		},
		fr: {
			title: "Solutions, capacités et produits associés",
			mainDescription:
				"Nous pouvons vous aider à obtenir les matériaux dont vous avez besoin et à fabriquer les pièces d'une manière abordable.",
			secondaryContent: [
				{
					title: "Solutions et capacités",
					jumpToLabel: "Plus de solutions métalliques",
					jumpToLink: "/metal-solutions/",
					bullets: [
						{
							title: "Laser à tubes",
							iconType: "capabilities-tube-laser",
							link: "/metal-solutions/capabilities/bar-tube-structural-processing/tube-laser/",
						},
						{
							title: "Traitement des plaques",
							link: "/metal-solutions/capabilities/plate-processing/",
							iconType: "solution-fabrication",
						},
						{
							title: "Profilé d'aluminium",
							link: "/metal-solutions/solutions/custom-aluminum-extrusions/",
							iconType: "solution-custom-aluminum-extrusions",
						},
					],
				},
				{
					title: "Des produits",
					jumpToLabel: "Plus de produits métalliques",
					jumpToLink: "/store",
					bullets: [
						{
							title: "Feuille d'aluminium",
							imageUrl: "/static-assets/images/renderings/AluminumSheet.png",
							link: "/aluminum/aluminum-sheet/",
						},
						{
							title: "Plaque de roulement",
							imageUrl:
								"/static-assets/images/renderings/AluminumTreadplateDiamond.png",
							link: "/aluminum/aluminum-diamond-tread-plate-expanded-metal-flooring",
						},
						{
							title: "Rouleau de solin sans soudure",
							imageUrl: "/static-assets/images/renderings/AluminumCoil.png",
							link: "/aluminum/aluminum-sheet/",
						},
					],
				},
			],
		},
		es: {
			title: "Soluciones, capacidades y productos relacionados",
			mainDescription:
				"Podemos ayudarle a obtener el material que necesita y procesar piezas de una forma rentable.",
			secondaryContent: [
				{
					title: "Soluciones y capacidades",
					jumpToLabel: "Más soluciones de metal",
					jumpToLink: "/metal-solutions/",
					bullets: [
						{
							title: "Corte con láser de tubos",
							iconType: "capabilities-tube-laser",
							link: "/metal-solutions/capabilities/bar-tube-structural-processing/tube-laser/",
						},
						{
							title: "Procesamiento de placas",
							link: "/metal-solutions/capabilities/plate-processing/",
							iconType: "solution-fabrication",
						},
						{
							title: "Extrusión de aluminio",
							link: "/metal-solutions/solutions/custom-aluminum-extrusions/",
							iconType: "solution-custom-aluminum-extrusions",
						},
					],
				},
				{
					title: "Productos",
					jumpToLabel: "Más productos de metal",
					jumpToLink: "/store",
					bullets: [
						{
							title: "Hoja de aluminio",
							imageUrl: "/static-assets/images/renderings/AluminumSheet.png",
							link: "/aluminum/aluminum-sheet/",
						},
						{
							title: "Placa lagrimada",
							imageUrl:
								"/static-assets/images/renderings/AluminumTreadplateDiamond.png",
							link: "/aluminum/aluminum-diamond-tread-plate-expanded-metal-flooring",
						},
						{
							title: "Rollo de material para tejados",
							imageUrl: "/static-assets/images/renderings/AluminumCoil.png",
							link: "/aluminum/aluminum-sheet/",
						},
					],
				},
			],
		},
	};

export const PageContent: StaticPageContent = {
	meta: MetaObject,
	content: [
		HeroContent,
		ValueAddedBenefitsContent,
		SubPages,
		RelatedSolutionsAndCapabilitiesContent,
	],
};
