import { LanguageContent } from "@components/Shared/model/LanguageContent";
import { HearFromOurPeopleContent } from "./HearFromOurPeople";

export const HearFromOurContent: LanguageContent<HearFromOurPeopleContent> = {
	en: {
		title: "Hear From Our People",
		slides: [
			{
				quote: `A career in metal, especially at Ryerson, offers great fulfillment by combining the satisfaction of hands-on work with the pride of contributing to national productivity, all supported by an exceptional team.`,
				name: "Morgan",
				title: "Welder",
				imageUrl: "/Carousel.jpg",
				videoId: "https://www.youtube.com/watch?v=wkodpo88bXM&ab_channel=Ryerson",
			},
		],
	},
	fr: {
		title: "Témoignages de notre équipe",
		slides: [
			{
				quote: `Une carrière en métallurgie, notamment chez Ryerson, offre un grand épanouissement en alliant la satisfaction du travail pratique à la fierté de contribuer à la productivité nationale, le tout soutenu par une équipe exceptionnelle.`,
				name: "Morgan",
				title: "Soudeur",
				imageUrl: "/Carousel.jpg",
				videoId: "https://www.youtube.com/watch?v=wkodpo88bXM&ab_channel=Ryerson",
			},
		],
	},
	es: {
		title: "Escuche sobre nuestras personas",
		slides: [
			{
				quote: `Una carrera en metales, especialmente en Ryerson, ofrece una gran satisfacción al combinar la satisfacción del trabajo práctico con el orgullo de contribuir a la productividad nacional, apoyado por un equipo excepcional.`,
				name: "Morgan",
				title: "Soldador",
				imageUrl: "/Carousel.jpg",
				videoId: "https://www.youtube.com/watch?v=wkodpo88bXM&ab_channel=Ryerson",
			},
		],
	},
};
