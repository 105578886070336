import { StaticPageContent } from "@components/Shared/model/StaticPageContent";
import { CustomizedMetalSolutionsContent } from "@components/Shared/CustomizedMetalSolutions/CustomizedMetalSolutions";
import { BeyondTheCostProps } from "@components/CustomizedMetalSolutions/BeyondTheCost/BeyondTheCost";
import { MoreThanMetalProps } from "@components/CustomizedMetalSolutions/MoreThanMetal/MoreThanMetal";
import { ResilientSupplyChainProps } from "@components/WhyRyerson/CustomizedMetalSolutions/ResilientSupplyChain/ResilientSupplyChain";
import { LanguageContent } from "@components/Shared/model/LanguageContent";
import { MetaContent } from "@components/Shared/model/MetaHelmet";

export const MetaObject: LanguageContent<MetaContent> = {
	en: {
		title: "Customized Metal Solutions - Ryerson",
		description: `An extensive range of processing and fabrication capabilities. Here is what that all means for you: Customized Metal Solutions.`,
		url: `/why-ryerson/customized-metal-solutions`,
		company: true,
	},
	fr: {
		title: "Customized Metal Solutions - Ryerson",
		description: `An extensive range of processing and fabrication capabilities. Here is what that all means for you: Customized Metal Solutions.`,
		url: `/why-ryerson/customized-metal-solutions`,
		company: true,
	},
	es: {
		title: "Customized Metal Solutions - Ryerson",
		description: `An extensive range of processing and fabrication capabilities. Here is what that all means for you: Customized Metal Solutions.`,
		url: `/why-ryerson/customized-metal-solutions`,
		company: true,
	},
};

export const HeroContent: LanguageContent<CustomizedMetalSolutionsContent> = {
	en: {
		sectionTitle: "Customized Metal Solutions",
		sectionDescription: [
			"More than 180 years of experience. 70,000+ products. An extensive range of processing and fabrication capabilities. Here is what that all means for you: Customized Metal Solutions.",
			"Let’s dig deeper into the story behind Customized Metal Solutions.",
		],
		anchorLinks: [
			{
				linkText: "More Than Metal",
				linkPath: "#more-than-metal",
			},
			{
				linkText: "Resilient Supply Chain",
				linkPath: "#resilient-supply-chain",
			},
			{
				linkText: "Beyond the Cost",
				linkPath: "#beyond-the-cost",
			},
		],
		imagePath: "/CustomizedMetalSolutionsGraph.svg",
	},
	fr: {
		sectionTitle: "Produits métallurgiques personnalisés",
		sectionDescription: [
			"Plus de 180 ans d’expérience. Plus de 70 000 produits. Une vaste gamme de capacités de traitement et de fabrication. Voici ce que cela signifie pour vous : Des solutions métallurgiques personnalisées.",
			"Explorons l’histoire qui se cache derrière les solutions métallurgiques personnalisées.",
		],
		anchorLinks: [
			{
				linkText: "Plus que du métal",
				linkPath: "#more-than-metal",
			},
			{
				linkText: "Une chaîne d’approvisionnement résiliente",
				linkPath: "#resilient-supply-chain",
			},
			{
				linkText: "Au-delà du coût",
				linkPath: "#beyond-the-cost",
			},
		],
		imagePath: "/CustomizedMetalSolutionsGraph.svg",
	},
	es: {
		sectionTitle: "Soluciones de metal personalizadas",
		sectionDescription: [
			"Más de 180 años de experiencia. Más de 70,000 productos. Una gama extensa de capacidades de procesamiento y fabricación. Esto es lo que todo esto significa para usted: Soluciones de metal personalizadas.",
			"Profundicemos en la historia de las soluciones de metal personalizadas.",
		],
		anchorLinks: [
			{
				linkText: "Más que metal",
				linkPath: "#more-than-metal",
			},
			{
				linkText: "Cadena de suministro resiliente",
				linkPath: "#resilient-supply-chain",
			},
			{
				linkText: "Más allá del costo",
				linkPath: "#beyond-the-cost",
			},
		],
		imagePath: "/CustomizedMetalSolutionsGraph.svg",
	},
};

export const MoreThanMetalContent: LanguageContent<MoreThanMetalProps> = {
	en: {
		sectionTitle: "More Than Metal",
		imageUrl: "/images/why-ryerson/MoreThanMetal.png",
		description1:
			"We offer the products and services to help you deliver more with fewer resources.",
		listData: [
			{
				listTitle: "How Do You Respond to the New Normal?",
				listItems: [
					{
						counter: "-",
						text: "Companies have been forced to shut down operations or shift focus.",
					},
					{ counter: "-", text: "Pricing is increasingly volatile." },
					{ counter: "-", text: "Uncertainty in material availability or delivery." },
				],
			},
			{
				listTitle: "You are forced to do more with less. This begs two questions:",
				listItems: [
					{ counter: "1.", text: "How will this impact you?" },
					{ counter: "2.", text: "How will this impact your customers?" },
				],
			},
		],
		description2:
			"You need a supplier to help carry the load by providing value and service. Beyond the metal, Ryerson has an extensive range of processing and fabrication capabilities.",
		processingCard: [
			{
				imageUrl: "/SheetCoilUpdate.png",
				cardTitle: "Sheet & Coil \n Processing",
				cardContext:
					"From cut-to-length sheets to laser-cut parts, our specialized processing centers convert coil to sheet and light plate in standard or custom lengths.                                                                ",
			},
			{
				imageUrl: "/Pipes.png",
				cardTitle: "Bar, Tube, \n & Structural Processing",
				cardContext:
					"Your bar, tube, pipe, or structural shape needs to be cut to length and finished for assembly. Our in-depth metal professionals help ensure you use the right process and most optimal equipment.",
			},
			{
				imageUrl: "/Plate.png",
				cardTitle: "Plate \n Processing",
				cardContext:
					"From simple finished parts to fabricated subassemblies, we take your stock plate through first and second-stage processing to provide high quality parts or assemblies.",
			},
		],
	},
	fr: {
		sectionTitle: "Plus que du métal",
		imageUrl: "/images/why-ryerson/MoreThanMetal.png",
		description1:
			"Nous offrons les produits et les services pour vous aider à être à la hauteur avec moins de ressources.",
		listData: [
			{
				listTitle: "Comment réagir face à la nouvelle normale?",
				listItems: [
					{
						counter: "-",
						text: "Les entreprises n’ont eu d’autres choix que d’interrompre leurs activités ou de changer leur orientation.",
					},
					{ counter: "-", text: "Les prix fluctuent de plus en plus." },
					{
						counter: "-",
						text: "- La disponibilité et la livraison des matériaux présentent des incertitudes.",
					},
				],
			},
			{
				listTitle:
					"Nous sommes obligés d’en faire plus avec moins. Cela soulève deux questions :",
				listItems: [
					{ counter: "1.", text: "Quels seront les effets en ce qui vous concerne?" },
					{ counter: "2.", text: "Quels seront les effets pour vos clients?" },
				],
			},
		],
		description2:
			"Vous avez besoin d’un fournisseur pour vous aider à assumer le fardeau en vous offrant de la valeur et du service. Au-delà du métal, Ryerson possède une vaste gamme de capacités de traitement et de fabrication.",
		processingCard: [
			{
				imageUrl: "/SheetCoil.png",
				cardTitle: "Traitement de bobines \n et de feuilles ",
				cardContext:
					"Des feuilles coupées en longueur aux pièces coupées au laser, nos centres de transformation spécialisés convertissent les bobines en feuilles et en plaques légères de longueur standard ou personnalisée.",
			},
			{
				imageUrl: "/Pipes.png",
				cardTitle: "Traitement de barres, de tubes \n et de formes structurales",
				cardContext:
					"Votre barre, tube, tuyau ou forme structurale doit être coupé en longueur et fini pour l’assemblage. Nos experts des métaux s’assurent que vous choisissez le bon processus et l’équipement idéal.",
			},
			{
				imageUrl: "/Plate.png",
				cardTitle: "Traitement \n de plaques ",
				cardContext:
					"Des pièces à finition simple aux sous-assemblages fabriqués, nous transformons votre plaque d’origine grâce à une première, puis à une deuxième transformation afin de vous offrir des pièces ou des assemblages de haute qualité.",
			},
		],
	},
	es: {
		sectionTitle: "Más que metal",
		imageUrl: "/images/why-ryerson/MoreThanMetal.png",
		description1:
			"Ofrecemos productos y servicios para ayudarle a ofrecer más con menos recursos.",
		listData: [
			{
				listTitle: "¿Cómo respondemos a la nueva normalidad?",
				listItems: [
					{
						counter: "-",
						text: "Las compañías se han visto forzadas a cerrar operaciones o a cambiar su enfoque.",
					},
					{
						counter: "-",
						text: "Los precios son increíblemente volátiles.",
					},
					{
						counter: "-",
						text: "Incertidumbre en la disponibilidad o entrega de material.",
					},
				],
			},
			{
				listTitle: "Usted es forzado a hacer más con menos. Esto crea dos preguntas:",
				listItems: [
					{ counter: "1.", text: "¿Cómo lo impactará esto?" },
					{ counter: "2.", text: "¿Cómo impactará esto a sus clientes?" },
				],
			},
		],
		description2:
			"Usted necesita un proveedor que le ayude a llevar la carga al proporcionar valor y servicio. Más allá del metal, Ryerson cuenta con una gama extensa de capacidades de procesamiento y fabricación.",
		processingCard: [
			{
				imageUrl: "/SheetCoil.png",
				cardTitle: "Procesamiento de \n hojas y rollos ",
				cardContext:
					"Desde hojas cortadas a longitud hasta partes cortadas con láser, nuestros centros de procesamiento especializados convierten rollos en hojas y placas ligeras en longitudes a la medida.",
			},
			{
				imageUrl: "/Pipes.png",
				cardTitle: "Procesamiento de barra, \n tubo y estructural",
				cardContext:
					"Su forma de barra, tubo o estructural necesita cortarse a la medida y terminarse para el ensamble. Nuestros expertos en metales a fondo ayudan a asegurar que usted utilice el proceso correcto y el equipo óptimo.",
			},
			{
				imageUrl: "/Plate.png",
				cardTitle: "Procesamiento \n de placas ",
				cardContext:
					"Desde parte de acabado simple hasta subensambles fabricados, llevamos sus placas de inventario a través del procesamiento de primera y segunda etapa para ofrecerle partes y ensambles de alta calidad.",
			},
		],
	},
};
export const ResilientSupplyChainContent: LanguageContent<ResilientSupplyChainProps> = {
	en: {
		sectionTitle: "Resilient Supply Chain",
		title1: "Resilient",
		title2: "Supply Chain",
		description1:
			"Work with partners that can deliver greater visibility, capability, inventory, demand, and risk management across the value chain. Ones that can provide local presence along with national scale.",
		description2:
			"Whether you work for a small shop or a large company with multiple locations, we take an end-to-end approach in managing the right supply chain solutions for your business. This includes:",
		questionsContext:
			"In response to changing demands, we partner with you to deliver supply-chain solutions tailored to meet the needs of your business.",
		question1: "Is Your Supply Chain Shock Absorbant?",
		answer1:
			"Supply chains are made up of constantly changing variables. Ever-changing market conditions force you to expect the unexpected.",
		background: "tertiary",
		imageUrl: "/ResilientSupplyUpdate.jpg",
		content: [
			{
				subTitle: "Comprehensive Programs",
				context: "Designed to help compress lead times and improve your speed to market.",
			},
			{
				subTitle: "Ownership of the Process",
				context: "The ability to produce parts in-house or outsource the production.",
			},
			{
				subTitle: "Purchasing Power",
				context:
					"Relationships with domestic mills and importers means we can secure the product you need, when you need it.",
			},
		],
		buttonLabel: "See how we achieve \n exceptional quality",
		cardItems: [
			{
				imageUrl: "/images/metal-solutions/solutions/solutions/GlobalAccountsUpdate3.jpg",
				title: "Global Accounts",
				context:
					"Are you a large OEM with multiple manufacturing locations across North America? Let us be your single point of contact for across the supply chain so you can focus on what you do best.",
				alignment: "left",
			},
			{
				imageUrl: "/images/why-ryerson/CMSQuality.jpg",
				title: " Product Quality",
				context:
					"We are continuously investing in methods that allow us to analyze, reduce, and/or eliminate risk in our processes.",
				alignment: "right",
			},
		],
	},
	fr: {
		sectionTitle: "Une chaîne d’approvisionnement résiliente",
		title1: "Une chaîne",
		title2: "d’approvisionnement résiliente",
		description1:
			"Travaillez avec des partenaires qui peuvent offrir davantage de visibilité, de capacité, de stocks, de demande et de gestion du risque tout au long de la chaîne d’approvisionnement. Ceux qui peuvent assurer une présence locale à l’échelle nationale.",
		description2:
			"Que vous travailliez pour un petit atelier ou une grande société ayant plusieurs sites, nous adoptons une approche de bout en bout en ce qui a trait à la gestion des solutions de chaîne d’approvisionnement qui conviennent à votre entreprise. Voici ce que cela comprend:",
		questionsContext:
			"En réponse aux demandes changeantes, nous faisons équipe avec vous pour vous offrir des solutions par rapport à la chaîne d’approvisionnement qui sont adaptées aux besoins de votre entreprise.",
		question1: "Votre chaîne d’approvisionnement est-elle en mesure d’absorber les chocs?",
		answer1:
			"Les chaînes d’approvisionnement sont composées de facteurs qui évoluent constamment. Les conditions en constantes évolutions vous obligent à vous attendre à l’inattendu.",
		background: "tertiary",
		imageUrl: "/ResilientSupplyUpdate.jpg",
		content: [
			{
				subTitle: "Programmes complets",
				context:
					"Conçus pour réduire les délais d’approvisionnement et améliorer votre rapidité sur le marché.",
			},
			{
				subTitle: "Prise en charge du processus",
				context:
					"La possibilité de produire des pièces en interne ou de sous-traiter la production.",
			},
			{
				subTitle: "Pouvoir d’achat",
				context:
					"Nos relations avec les usines nationales et les importateurs signifient que nous pouvons obtenir le produit dont vous avez besoin, au moment où vous en avez besoin.",
			},
		],
		buttonLabel: "Découvrez comment nous obtenons \n une qualité exceptionnelle",
		cardItems: [
			{
				imageUrl: "/images/metal-solutions/solutions/solutions/GlobalAccountsUpdate3.jpg",
				title: "Comptes internationaux",
				context:
					"Vous êtes un grand fabricant d’équipement d’origine ayant plusieurs sites de fabrication en Amérique du Nord? Laissez-nous être votre point de contact unique pour l’ensemble de votre chaîne d’approvisionnement afin que vous puissiez vous concentrer sur ce que vous faites le mieux.",
				alignment: "left",
			},
			{
				imageUrl: "/images/why-ryerson/CMSQuality.jpg",
				title: "Qualité des produits",
				context:
					"Nous investissons continuellement dans des méthodes qui nous permettent d’analyser, de réduire ou d’éliminer les risques dans nos processus.",
				alignment: "right",
			},
		],
	},
	es: {
		sectionTitle: "Cadena de suministro resiliente",
		title1: "Cadena de",
		title2: "suministro resiliente",
		description1:
			"Trabajamos con socios que pueden ofrecer mayor visibilidad, capacidad, inventario, demanda y manejo del riesgo en la cadena de valor. Son socios que pueden proporcionar presencia local junto con escala a nivel nacional.",
		description2:
			"Ya sea que usted trabaje para un taller pequeño o una compañía grande con múltiples ubicaciones, nosotros tenemos un enfoque de extremo a extremo en el manejo de las soluciones correctas de la cadena de suministro para su negocio. Esto incluye:",
		questionsContext:
			"En respuesta a las demandas cambiantes, nos asociamos con usted para ofrecer soluciones de cadena de suministro diseñadas a la medida para atender las demandas de su negocio.",
		question1: "¿Su cadena de suministro absorbe los impactos?",
		answer1:
			"Las cadenas de suministro están hechas de variables que cambian constantemente. Las condiciones del mercado siempre cambiantes lo fuerzan a esperar lo inesperado.",
		background: "tertiary",
		imageUrl: "/ResilientSupplyUpdate.jpg",
		content: [
			{
				subTitle: "Programas extensos",
				context:
					"Diseñados para ayudar a reducir los tiempos de espera y mejorar su velocidad al mercado.",
			},
			{
				subTitle: "Propiedad del proceso",
				context:
					"La capacidad de producir partes internamente o subcontratar la producción.",
			},
			{
				subTitle: "Poder de compra",
				context:
					"Las relaciones con fábricas nacionales e importadores significa que podemos asegurar el producto que usted necesita, cuando lo necesite.",
			},
		],
		buttonLabel: "See how we achieve \n exceptional quality",
		cardItems: [
			{
				imageUrl: "/images/metal-solutions/solutions/solutions/GlobalAccountsUpdate3.jpg",
				title: "Cuentas globales",
				context:
					"¿Es usted fabricante de equipos originales grandes con una gran cantidad de plantas de fabricación en Norteamérica? Permítanos ser su punto único de contacto para toda la cadena de suministro de manera que usted pueda concentrarse en lo que mejor sabe hacer.",
				alignment: "left",
			},
			{
				imageUrl: "/images/why-ryerson/CMSQuality.jpg",
				title: "Calidad de los productos",
				context:
					"Invertimos continuamente en métodos que nos permiten analizar, reducir o eliminar el riesgo en nuestros procesos.",
				alignment: "right",
			},
		],
	},
};

export const BeyondTheCostContent: LanguageContent<BeyondTheCostProps> = {
	en: {
		title: "Beyond the Cost",
		subtitle:
			"As our relationship evolves, we take on a larger role in helping you execute on growth, speed-to-market, and improved financial performance of your business.",
		subheading: "Are You Focusing Only on Costs?",
		paragraph0:
			"Focusing only on cost can result in extended lead times, poor quality, and increased labor costs. Consider everything the supplier can offer, such as:",
		bulletPoints: [
			"—  Material availability and value-added services",
			"—  Risk management",
			"—  Reduced labor costs",
		],
		paragraph1: [
			{
				text: "We build a strategic business relationship with you, taking on a larger role in helping you to execute on ",
			},
			{
				text: "growth, speed-to-market, and improved financial performance.",
				color: "#002855",
			},
		],
		paragraph2: [
			{
				text: "As business cycles ebb and flow, we are here to help you manage labor, capacity, and capital. We partner with you to ",
			},
			{
				text: "customize the right solution to fit your needs.",
				color: "#002855",
			},
		],
		imagePath: "/images/why-ryerson/BeyondTheCost.jpg",
		secondaryContent: [
			{
				title: "Advanced Processing",
				link: "/metal-solutions/solutions/advanced-processing/",
				image: "/images/why-ryerson/AdvancedProcessingUpdate.png",
			},
			{
				title: "Industries We Serve",
				link: "/metal-solutions/industries/",
				image: "/images/why-ryerson/IndustriesWeServeUpdate2.jpg",
			},
		],
	},
	fr: {
		title: "Au-delà du coût",
		subtitle:
			"À mesure que notre relation se développe avec vous, nous assumons un plus grand rôle en vous aidant à assurer la croissance de votre entreprise, à accélérer la mise en marché et à améliorer votre rendement financier.",
		subheading: "Le coût est-il le seul facteur dont vous tenez compte?",
		paragraph0:
			"Prendre en compte uniquement ce facteur peut entraîner des délais de production prolongés, une mauvaise qualité et l’augmentation des coûts de la main-d’œuvre. Pensez plutôt à tous les éléments que peut offrir un fournisseur, tels que:",
		bulletPoints: [
			"—  La disponibilité des matériaux et les services à valeur ajoutée",
			"—  La gestion du risque",
			"—  La réduction des coûts de main-d’œuvre",
		],
		paragraph1: [
			{
				text: "Nous établissons une relation d’affaires stratégique avec vous et assumons un plus grand rôle en vous aidant à assurer ",
			},
			{
				text: "la croissance de votre entreprise, à accélérer la mise en marché et à améliorer votre rendement financier.",
				color: "#002855",
			},
		],
		paragraph2: [
			{
				text: "Alors que les cycles économiques fluctuent, nous sommes là pour vous aider à gérer la main-d’œuvre, la capacité et les fonds. Nous faisons équipe avec vous afin ",
			},
			{
				text: "de  personnaliser la solution qui répond le mieux à vos besoins.",
				color: "#002855",
			},
		],
		imagePath: "/CustomizedMetalSolutionsBeyondTheCost.jpg",
		secondaryContent: [
			{
				title: "Transformation évoluée",
				link: "/metal-solutions/solutions/advanced-processing/",
				image: "/images/why-ryerson/AdvancedProcessingUpdate.png",
			},
			{
				title: "Les secteurs dans lesquels nous nous spécialisons",
				link: "/metal-solutions/industries/",
				image: "/images/why-ryerson/IndustriesWeServeUpdate2.jpg",
			},
		],
	},
	es: {
		title: "Más allá del costo",
		subtitle:
			"A medida que nuestra relación evoluciona, asumimos un papel principal para ayudarle a crecer, mejorar la velocidad de comercialización y mejorar el rendimiento financiero de su negocio.",
		subheading: "¿Solamente se enfoca en los costos?",
		paragraph0:
			"El enfoque exclusivo en los costos puede dar lugar a tiempos de espera prolongados, mala calidad y aumento de los costos de mano de obra. Considere todo lo que el proveedor puede ofrecerle, por ejemplo:",
		bulletPoints: [
			"—  Disponibilidad de material y servicios de valor agregado",
			"—  Manejo del riesgo",
			"—  Costos de mano de obra reducidos",
		],
		paragraph1: [
			{
				text: "Establecemos con usted una relación empresarial estratégica, asumiendo un papel principal para ayudarle ",
			},
			{
				text: "a crecer, mejorar la velocidad de comercialización y mejorar el rendimiento financiero.",
				color: "#002855",
			},
		],
		paragraph2: [
			{
				text: "A medida que los ciclos comerciales suben y bajan, nosotros estamos aquí para ayudarle a manejar la mano de obra, la capacidad y el capital. Nos asociamos con usted para ",
			},
			{
				text: "personalizar la solución ideal que se adapte a sus necesidades.",
				color: "#002855",
			},
		],
		imagePath: "/CustomizedMetalSolutionsBeyondTheCost.jpg",
		secondaryContent: [
			{
				title: "Procesamiento avanzado",
				link: "/metal-solutions/solutions/advanced-processing/",
				image: "/images/why-ryerson/AdvancedProcessingUpdate.png",
			},
			{
				title: "Industrias a las que servimos",
				link: "/metal-solutions/industries/",
				image: "/images/why-ryerson/IndustriesWeServeUpdate2.jpg",
			},
		],
	},
};

export const PageContent: StaticPageContent = {
	meta: MetaObject,
	content: [HeroContent, MoreThanMetalContent, ResilientSupplyChainContent, BeyondTheCostContent],
};
