import { StaticPageContent } from "@components/Shared/model/StaticPageContent";
import { LanguageContent } from "@components/Shared/model/LanguageContent";
import { ValueAddedBenefitsProperties } from "@components/Industries/ValueAddedBenefits/ValueAddedBenefits";
import { HeroProps } from "@components/Industries/Hero/Hero";
import { CustomizedMetalSolutionsProps } from "@components/Industries/CustomizedMetalSolutions/CustomizedMetalSolutions";
import { RelatedSolutionsCapabilitiesAndProductsProperties } from "@components/Industries/RelatedSolutionsCapabilitiesAndProducts/RelatedSolutionsCapabilitiesAndProducts";
import { MetaContent } from "@components/Shared/model/MetaHelmet";

export const MetaObject: LanguageContent<MetaContent> = {
	en: {
		title: "Steel For Industrial Automation - Ryerson",
		description:
			"Metals for packaging and processing equipment. Full service steel plate, sheet, bar, and tube processing and fabrication.",
		url: `/metal-solutions/industries/industrial-automation`,
		company: true,
	},
	fr: {
		title: "Steel For Industrial Automation - Ryerson",
		description:
			"Metals for packaging and processing equipment. Full service steel plate, sheet, bar, and tube processing and fabrication.",
		url: `/metal-solutions/industries/industrial-automation`,
		company: true,
	},
	es: {
		title: "Steel For Industrial Automation - Ryerson",
		description:
			"Metals for packaging and processing equipment. Full service steel plate, sheet, bar, and tube processing and fabrication.",
		url: `/metal-solutions/industries/industrial-automation`,
		company: true,
	},
};

export const HeroContent: LanguageContent<HeroProps> = {
	en: {
		heroTitle: "Industrial Automation",
		heroDescription: [
			"Things move quickly in the industrial automation market. As a manufacturer in this space, you need the metal and services that can keep up with the pace of demand.",
			"Understanding the need for rapid response, we create precision components from custom specifications and leverage high-volume delivery capabilities to produce fully processed components quickly and responsively. ",
		],
		buttonLabel: "Contact a Professional",
		hasVideo: true,
		heroVideoId: "4KgacAzbshU",
		isWatermarkHero: true,
		watermarkUrl: "/images/industries/PackagingProcessingWatermark.jpg",
	},
	fr: {
		heroTitle: "Automatisation industrielle",
		heroDescription: [
			"Les choses évoluent rapidement sur le marché de l'automatisation industrielle. En tant que fabricant dans ce secteur, vous avez besoin de métaux et de services capables de suivre le rythme de la demande.",
			"Parce que nous comprenons la nécessité d'une réponse rapide, nous créons des pièces précises adaptées aux spécifications du client et exploitons nos capacités de livraison de volume élevé pour produire des pièces entièrement traitées de manière rapide et réactive.",
		],
		buttonLabel: "Communiquer avec un expert",
		hasVideo: true,
		heroVideoId: "4KgacAzbshU",
		isWatermarkHero: true,
		watermarkUrl: "/images/industries/PackagingProcessingWatermark.jpg",
	},
	es: {
		heroTitle: "Automatización industrial",
		heroDescription: [
			"Las cosas avanzan rápido en el mercado de la automatización industrial. Como fabricante en este espacio, usted necesita el metal y los servicios que puedan mantenerse al ritmo de la demanda.",
			"Al comprender la necesidad de una respuesta rápida, creamos componentes de precisión a partir de especificaciones personalizadas y aprovechamos las capacidades de entrega de grandes volúmenes para producir componentes completamente procesados de manera rápida y receptiva.",
		],
		buttonLabel: "Comuníquese con un experto",
		hasVideo: true,
		heroVideoId: "4KgacAzbshU",
		isWatermarkHero: true,
		watermarkUrl: "/images/industries/PackagingProcessingWatermark.jpg",
	},
};
export const ValueAddedBenefitsContent: LanguageContent<ValueAddedBenefitsProperties> = {
	en: {
		title: "Value-Added Benefits",
		mainDescription: `From raw materials to custom ready-for-assembly components, we are the single source for your metal needs.`,
		buttonLabel: "Let's Talk!",
		secondaryContent: [
			{
				title: "Advanced Processing",
				description: `Machining capabilities that result in accurate, repeatable cuts that produce parts with precision, as well as pre-polished materials and custom parts for complete framework are just a taste of what our advanced processing capabilities can deliver for you.`,
				imageUrl: "/images/industries/heavy-equipment/AdvancedProcessingInvertColors.jpg",
				showImageOnDesktop: false,
				showImageOnMobile: true,
			},
			{
				title: "Consistent, Quality Polishing",
				description: `Quality should never be a concern when it comes to your polished materials. Our specialized polishes reduce the need for harsh cleaning, eliminate surface discoloration, and remove minor imperfections.`,
				imageUrl: "/images/industries/packaging-processing/PolishUpdate.jpg",
				showImageOnDesktop: true,
				showImageOnMobile: true,
			},
		],
	},
	fr: {
		title: "Avantages à valeur ajoutée",
		mainDescription: `Que vous ayez besoin de matières premières ou de pièces prêtes à assembler, nous pouvons répondre à tous vos besoins d'approvisionnement.`,
		buttonLabel: "Discutons",
		secondaryContent: [
			{
				title: "Traitement avancé",
				description: `Nos capacités d'usinage assurant des coupes précises et reproductibles qui produisent des pièces avec précision, ainsi que des matériaux prépolis et des pièces personnalisées pour l'ensemble de la structure, ne sont qu'un avant-goût de nos capacités de traitement avancé.`,
				imageUrl: "/images/industries/heavy-equipment/AdvancedProcessingInvertColors.jpg",
				showImageOnDesktop: false,
				showImageOnMobile: true,
			},
			{
				title: "Polissage uniforme de haute qualité",
				description: `La qualité du polissage de vos matériaux ne devrait jamais être une source de préoccupation. Nos polis spécialisés réduisent la nécessité de nettoyer en profondeur, éliminent la décoloration à la surface et les imperfections mineures.`,
				imageUrl: "/images/industries/packaging-processing/PolishUpdate.jpg",
				showImageOnDesktop: true,
				showImageOnMobile: true,
			},
		],
	},
	es: {
		title: "Beneficios de valor agregado",
		mainDescription: `Desde materias primas hasta componentes personalizados listos para ensamblar, somos una fuente única para sus necesidades de metal.`,
		buttonLabel: "Hablemos",
		secondaryContent: [
			{
				title: "Procesamiento avanzado",
				description: `Capacidades correspondientes que resultan en cortes precisos y repetibles que producen piezas con precisión, así como materiales prepulidos y piezas personalizadas para una estructura completa son solo una muestra de lo que pueden ofrecerle nuestras capacidades avanzadas de procesamiento.`,
				imageUrl: "/images/industries/heavy-equipment/AdvancedProcessingInvertColors.jpg",
				showImageOnDesktop: false,
				showImageOnMobile: true,
			},
			{
				title: "Pulido uniforme y de calidad",
				description: `La calidad nunca debe ser una preocupación cuando se trata de sus materiales pulidos. Nuestros pulidores especializados reducen la necesidad de una limpieza agresiva y eliminan la decoloración de la superficie y las imperfecciones menores.`,
				imageUrl: "/images/industries/packaging-processing/PolishUpdate.jpg",
				showImageOnDesktop: true,
				showImageOnMobile: true,
			},
		],
	},
};

export const CustomizedMetalSolutionsContent: LanguageContent<CustomizedMetalSolutionsProps> = {
	en: {
		sectionHeading: "Customized Metal Solutions for",
		title: "Industrial Automation",
		mainDescription: `How do you propel your operations to meet the unprecedented surge in demand without compromising cost or quality? Whether it's streamlining processes, optimizing production, or overcoming labor shortages, our industry professionals are here to provide solutions tailored to industrial automation.`,
		contact: {
			heading: "Contact your Industrial Automation Professional",
			name: "Mark Brewer",
			button: "Contact a Professional",
			title: "Vertical Market Manager, Industrial Automation",
			email: "mark.brewer@ryerson.com",
			portrait: "/images/industries/packaging-processing/MarkBrewer.jpg",
		},
		thinglink: {
			description:
				"Ryerson has a team dedicated to industrial automation. They are actively solving problems for companies like yours by delivering the material and designing a supply chain that addresses a range of needs.",
			id: "1517933345307099138",
			width: 1024,
			height: 684,
			originalHeight: 684,
			originalWidth: 1024,
		},
	},
	fr: {
		sectionHeading: "Des solutions métallurgiques personnalisées pour",
		title: "Automatisation industrielle",
		mainDescription: `Comment pouvez-vous propulser vos opérations pour répondre à l'augmentation sans précédent de la demande, sans compromettre le coût ni la qualité? Qu'il s'agisse de rationaliser les processus, d'optimiser la production ou de surmonter les pénuries de main-d'œuvre, nos professionnels de l'industrie vous offrent des solutions adaptées à l'automatisation industrielle.`,
		contact: {
			heading: "Contactez votre professionnel de l'automatisation industrielle",
			name: "Mark Brewer",
			button: "Contactez un professionnel",
			title: "Chef de marché vertical, Automatisation industrielle",
			email: "mark.brewer@ryerson.com",
			portrait: "/images/industries/packaging-processing/MarkBrewer.jpg",
		},
		thinglink: {
			description:
				"Ryerson a mis en place une équipe spécialisée dans l'automatisation industrielle. Elle peut résoudre de manière proactive des problèmes pour des entreprises comme la vôtre en fournissant le matériel et en concevant une chaîne d'approvisionnement qui répond à divers besoins.",
			id: "1517933345307099138",
			width: 1024,
			height: 684,
			originalHeight: 684,
			originalWidth: 1024,
		},
	},
	es: {
		sectionHeading: "Soluciones de metal personalizadas para",
		title: "Automatización industrial",
		mainDescription: `¿Cómo impulsa sus operaciones para satisfacer el inédito aumento en la demanda sin poner en riesgo los costos o la calidad? Ya sea simplificando los procesos, optimizando la producción o superando la escasez de mano de obra, nuestros profesionales de la industria están aquí para ofrecerle soluciones personalizadas para la automatización industrial.`,
		contact: {
			heading: "Contacta con tu Profesional en Automatización Industrial",
			name: "Mark Brewer",
			button: "Contacta con un profesional",
			title: "Gerente de Mercado Vertical, Automatización Industrial",
			email: "mark.brewer@ryerson.com",
			portrait: "/images/industries/packaging-processing/MarkBrewer.jpg",
		},
		thinglink: {
			description:
				"Ryerson tiene un equipo dedicado a la automatización industrial. Están trabajando activamente para resolver problemas para empresas como la suya al suministrar el material y diseñar una cadena de suministro que se enfoque en un rango de necesidades.",
			id: "1517933345307099138",
			width: 1024,
			height: 684,
			originalHeight: 684,
			originalWidth: 1024,
		},
	},
};
export const RelatedSolutionsCapabilitiesContent: LanguageContent<RelatedSolutionsCapabilitiesAndProductsProperties> =
	{
		en: {
			title: "Related Solutions, Capabilities, and Products",
			mainDescription: `Manufacturers in industrial automation must find new ways to be nimble and ready to compete faster than ever before, while accommodating multiple and/or more demanding products.`,
			secondaryContent: [
				{
					title: "Solutions & Capabilities",
					jumpToLabel: "More Metal Solutions",
					jumpToLink: "/metal-solutions/",
					bullets: [
						{
							title: "Tube Laser",
							iconType: "capabilities-tube-laser",
							link: "/metal-solutions/capabilities/bar-tube-structural-processing/tube-laser/",
						},
						{
							title: "Laser Cutting",
							iconType: "capabilities-plate-laser",
							link: "/metal-solutions/capabilities/plate-processing/laser-cutting/",
						},
						{
							title: "Fabrication",
							link: "/metal-solutions/capabilities/fabrication/",
							iconType: "capabilities-fabrication",
						},
					],
				},
				{
					title: "Products",
					jumpToLabel: "More Metal Products",
					jumpToLink: "/store",
					bullets: [
						{
							title: "Stainless steel sheet",
							imageUrl: "/static-assets/images/renderings/StainlessSheet.png",
							link: "/stainless/stainless-sheet",
						},
						{
							title: "Stainless steel tube",
							imageUrl: "/static-assets/images/renderings/StainlessTubeRound.png",
							link: "/stainless/stainless-tube-pipe",
						},
						{
							title: "Aluminum sheet",
							imageUrl: "/static-assets/images/renderings/AluminumSheet.png",
							link: "/aluminum/aluminum-sheet",
						},
					],
				},
			],
		},
		fr: {
			title: "Solutions, capacités et produits connexes",
			mainDescription: `Les fabricants d'équipement industriel doivent trouver de nouvelles manières d'être flexibles et plus rapides qu'avant pour affronter la concurrence, tout en offrant des produits multiples ou plus contraignants.`,
			secondaryContent: [
				{
					title: "Solutions et capacités",
					jumpToLabel: "Plus de solutions métalliques",
					jumpToLink: "/metal-solutions/",
					bullets: [
						{
							title: "Laser à tubes",
							iconType: "capabilities-tube-laser",
							link: "/metal-solutions/capabilities/bar-tube-structural-processing/tube-laser/",
						},
						{
							title: "Coupe au laser",
							iconType: "capabilities-plate-laser",
							link: "/metal-solutions/capabilities/plate-processing/laser-cutting/",
						},
						{
							title: "Fabrication",
							link: "/metal-solutions/capabilities/fabrication/",
							iconType: "capabilities-fabrication",
						},
					],
				},
				{
					title: "Produits",
					jumpToLabel: "Plus de produits métalliques",
					jumpToLink: "/store",
					bullets: [
						{
							title: "Feuille d'acier inoxydable",
							imageUrl: "/static-assets/images/renderings/StainlessSheet.png",
							link: "/stainless/stainless-sheet",
						},
						{
							title: "Tube d'acier inoxydable",
							imageUrl: "/static-assets/images/renderings/StainlessTubeRound.png",
							link: "/stainless/stainless-tube-pipe",
						},
						{
							title: "Feuille d'aluminium",
							imageUrl: "/static-assets/images/renderings/AluminumSheet.png",
							link: "/aluminum/aluminum-sheet",
						},
					],
				},
			],
		},
		es: {
			title: "Soluciones, capacidades y productos relacionados",
			mainDescription: `Los fabricantes de equipamiento de empaque y procesamiento deben encontrar nuevas formas de ser ágiles y estar preparados para competir más rápido que nunca, mientras se adaptan a productos múltiples o más exigentes.`,
			secondaryContent: [
				{
					title: "Soluciones y capacidades",
					jumpToLabel: "Más soluciones de metal",
					jumpToLink: "/metal-solutions/",
					bullets: [
						{
							title: "Láser de tubos",
							iconType: "capabilities-tube-laser",
							link: "/metal-solutions/capabilities/bar-tube-structural-processing/tube-laser/",
						},
						{
							title: "Corte por láser",
							iconType: "capabilities-plate-laser",
							link: "/metal-solutions/capabilities/plate-processing/laser-cutting/",
						},
						{
							title: " Fabricación",
							link: "/metal-solutions/capabilities/fabrication/",
							iconType: "capabilities-fabrication",
						},
					],
				},
				{
					title: "Productos",
					jumpToLabel: "Más productos de metal",
					jumpToLink: "/store",
					bullets: [
						{
							title: "Hoja de acero inoxidable",
							imageUrl: "/static-assets/images/renderings/StainlessSheet.png",
							link: "/stainless/stainless-sheet",
						},
						{
							title: "Tubo de acero inoxidable",
							imageUrl: "/static-assets/images/renderings/StainlessTubeRound.png",
							link: "/stainless/stainless-tube-pipe",
						},
						{
							title: "Hoja de aluminio",
							imageUrl: "/static-assets/images/renderings/AluminumSheet.png",
							link: "/aluminum/aluminum-sheet",
						},
					],
				},
			],
		},
	};

export const PageContent: StaticPageContent = {
	meta: MetaObject,
	content: [
		HeroContent,
		ValueAddedBenefitsContent,
		RelatedSolutionsCapabilitiesContent,
		CustomizedMetalSolutionsContent,
	],
};
