import { LanguageContent } from "@components/Shared/model/LanguageContent";
import { InOurDNAContentProps } from "./InOurDNA";

export const InOurDNAContent: LanguageContent<InOurDNAContentProps> = {
	en: {
		title: "In Our DNA",
		cards: [
			{
				title: "Training & Development",
				context:
					"At Ryerson, opportunities to grow and support for professional advancement are abundant through a broad range of training and educational opportunities.",
				backgroundUrl: "/images/ryerson-academy/TrainingAndDevelopment.jpg",
				link: "/about-us/careers/working-at-ryerson/training-and-development",
			},
			{
				title: "Diversity, Equity, & Inclusion",
				context:
					"Ryerson is committed to building a diverse and inclusive workplace of associates from varied cultures, educational backgrounds, perspectives and experiences.",
				backgroundUrl: "/images/ryerson-academy/DiversityInclusion.jpg",
				link: "/about-us/careers/working-at-ryerson/diversity-and-inclusion",
			},
			{
				title: "Ryerson Gives Back",
				context:
					"Ryerson invests in the community and our people in a variety of ways, from volunteerism to providing educational resources to help our employees and families advance their careers.",
				backgroundUrl: "/images/training-and-development/RGBUpdate.jpg",
				link: "/why-ryerson/ryerson-commitment/ryerson-gives-back",
			},
		],
	},
	fr: {
		title: "C’est dans notre ADN",
		cards: [
			{
				title: "Formation et développement",
				context:
					"Chez Ryerson, profitez d’un abondant soutien au développement professionnel et de plusieurs occasions pour grandir grâce à notre large éventail de possibilités de formation et d’éducation",
				backgroundUrl: "/images/ryerson-academy/TrainingAndDevelopment.jpg",
				link: "/about-us/careers/working-at-ryerson/training-and-development",
			},
			{
				title: "Équité, diversité et inclusion",
				context:
					"Ryerson s’engage à créer un milieu de travail diversifié et inclusif faisant affaire avec des associés issus de cultures, de milieux éducatifs, de perspectives et d’expériences variés.",
				backgroundUrl: "/images/ryerson-academy/DiversityInclusion.jpg",
				link: "/about-us/careers/working-at-ryerson/diversity-and-inclusion",
			},
			{
				title: "Communauté Ryerson Gives Back",
				context:
					"Ryerson investit dans la communauté et dans son personnel de diverses manières, du bénévolat à la fourniture de ressources éducatives, afin d’aider ses employés et leur famille à faire progresser leur carrière.",
				backgroundUrl: "/images/training-and-development/RGBUpdate.jpg",
				link: "/why-ryerson/ryerson-commitment/ryerson-gives-back",
			},
		],
	},
	es: {
		title: "En nuestro ADN",
		cards: [
			{
				title: "Capacitación y desarrollo",
				context:
					"En Ryerson, las oportunidades de crecer y el apoyo para el progreso profesional son abundantes a través de una amplia gama de oportunidades de capacitación y educación.",
				backgroundUrl: "/images/ryerson-academy/TrainingAndDevelopment.jpg",
				link: "/about-us/careers/working-at-ryerson/training-and-development",
			},
			{
				title: "Diversidad, equidad e inclusión",
				context:
					"Ryerson tiene el compromiso de crear un lugar de trabajo diverso e inclusivo de asociados de diversas culturas, contextos educativos, perspectivas y experiencias.",
				backgroundUrl: "/images/ryerson-academy/DiversityInclusion.jpg",
				link: "/about-us/careers/working-at-ryerson/diversity-and-inclusion",
			},
			{
				title: "Ryerson devuelve",
				context:
					"Ryerson invierte en la comunidad y en nuestra gente en muchas formas, desde el voluntariado hasta el proporcionar recursos educativos para ayudar a nuestros empleados y familias a crecer en sus carreras.",
				backgroundUrl: "/images/training-and-development/RGBUpdate.jpg",
				link: "/why-ryerson/ryerson-commitment/ryerson-gives-back",
			},
		],
	},
};
