import { EnvironmentHealthAndSafetyProps } from "./EnvironmentHealthAndSafety";
import { LanguageContent } from "@components/Shared/model/LanguageContent";

export const EnvironmentHealthAndSafetyContent: LanguageContent<EnvironmentHealthAndSafetyProps> = {
	en: {
		imageUrl: "/images/why-ryerson/ryerson-commitment/Commitment5.jpg",
		title: "Environment, Health, Safety",
		content:
			"By maintaining the highest environment, health, and safety standards in our facilities, we ensure our employees return home safe.",
		buttonLabel: "More about Environment, \n Health, Safety",
		moreAboutLink: "/why-ryerson/ryerson-commitment/environment-health-safety",
	},
	fr: {
		imageUrl: "/images/why-ryerson/ryerson-commitment/Commitment5.jpg",
		title: "Sécurité et hygiène du milieu",
		content:
			"En respectant les normes les plus élevées en matière de sécurité et d'hygiène du milieu dans nos installations, nous nous assurons de la protection de nos employés.",
		buttonLabel: "En savoir plus sur la sécurité \n et l'hygiène du milieu ",
		moreAboutLink: "/why-ryerson/ryerson-commitment/environment-health-safety",
	},
	es: {
		imageUrl: "/images/why-ryerson/ryerson-commitment/Commitment5.jpg",
		title: "Seguridad, Salud y Medio Ambiente",
		content:
			"Al mantener los estándares más altos de seguridad, salud y salud ambiental en nuestras instalaciones, nos aseguramos de que nuestros empleados vuelvan sanos y salvos a casa.",
		buttonLabel: "Más sobre Seguridad, \n Salud y Medio Ambiente ",
		moreAboutLink: "/why-ryerson/ryerson-commitment/environment-health-safety",
	},
};
