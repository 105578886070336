import { StaticPageContent } from "@components/Shared/model/StaticPageContent";
import { MetaContent } from "@components/Shared/model/MetaHelmet";
import { LanguageContent } from "@components/Shared/model/LanguageContent";

export const MetaObject: LanguageContent<MetaContent> = {
	en: {
		title: "Open Positions - Ryerson Canada",
		description: `Interested in joining the Ryerson team? Search current openings in your area.`,
		url: `/about-us/careers/canada`,
		company: true,
	},
	fr: {
		title: "Open Positions - Ryerson Canada",
		description: `Interested in joining the Ryerson team? Search current openings in your area.`,
		url: `/about-us/careers/canada`,
		company: true,
	},
	es: {
		title: "Open Positions - Ryerson Canada",
		description: `Interested in joining the Ryerson team? Search current openings in your area.`,
		url: `/about-us/careers/canada`,
		company: true,
	},
};

export type OpenPositionsProps = {
	title: string;
	button: OpportunityListProps;
	description: string[];
    contactEmail: string;
    contactEmailURL: string;
	futureOpportunitiesHeader: string;
	futureOpportunititesList: string[];
	currentOpportunitiesHeader: string;
	currentOpportunitiesList: OpportunityListProps[];
};

export type OpportunityListProps = {
	title: string;
	link: string;
};

export const OpenPositionsContent: LanguageContent<OpenPositionsProps> = {
	en: {
		title: "Open Positions - Canada",
		button: {
			title: "Open Positions - USA",
			link: "/about-us/careers/open-positions/",
		},
		description: [
			"Ryerson is 4,000 people strong, working in more than 100 locations around the world, and backed by more than 180 years in business. Throughout our history, we’ve always been firmly driven to create value for the customers, investors and communities we serve. You can see it in the ingenuity of our people. In the collective strength of our expertise. And, most evidently, in the driving mission that lays the foundation for our success and guides our everyday work.",
			"In business 178 years in North America, we offer a diverse range of metal products to our clients, including stainless, aluminum, carbon and alloy, flat/rolled/sheet products, nickel, brass and copper and pipes, valves and fittings.",
			"We offer exciting career opportunities in a variety of fields and promote from within, while tapping outside talent in order to diversify our strengths.  Our employees are our most valuable asset, so we offer competitive salary and benefits, along with an exciting, challenging atmosphere in a team environment.",
			"Whatever talents you have to offer, we have a place for you. Career opportunities in administration, finance, accounting, credit and purchasing exist at our Corporate offices in Ontario, while sales, operations, processing and distribution opportunities exist in any of our eight service centres located across Canada.",
			"Ryerson Canada is an Equal Opportunity Employer. We are committed to representing the diverse communities we serve. That’s why we encourage applications from visible minorities, aboriginal people, women, and people with disabilities.",
			"We are always looking for people with passion and drive who wish to share their unique talents.  Interested in a career with Ryerson Canada? Please forward your resume to ",
		],
        contactEmail: "HRCanada@ryerson.com",
        contactEmailURL: "mailto:HRCanada@ryerson.com",
		futureOpportunitiesHeader: "Example future career opportunities:",
		futureOpportunititesList: [
			"Sales (Inside/Outside and Sales Management)",
			"Operations (General Labour, Machine Operators, Logistics, Production Planning, Supervision and Management)",
			"Finance and Accounting (Accounts Payable and Accounts Receivable)",
			"Credit (Collection and Cash Application)",
			"Purchasing (Inventory Management and Buyers)",
			"Administration (General and Assisting)",
		],
		currentOpportunitiesHeader: "Current Hiring Opportunities:",
		currentOpportunitiesList: [
			{
				title: "Order Picker/Packer - Vaudreuil, QC",
				link: "https://www.ryerson.com/-/media/Canada-Positions/071621/Warehouse-Operative_Vaudreuil.ashx",
			},
			{
				title: "Order Picker/Packer Vaudreuil, QC (FR)",
				link: "https://www.ryerson.com/-/media/Canada-Positions/071621/Warehouse-Operative_Vaudreuil_Fr.ashx",
			},
			{
				title: "Multi-Market Operations Manager - Brampton, ON",
				link: "https://www.ryerson.com/-/media/Canada-Positions/020222/MMOM_Canada_-Brampton.ashx",
			},
			{
				title: "Bilingual Outside Sales Representative - Moncton, NB",
				link: "https://www.ryerson.com/-/media/Canada-Positions/020222/Bilingual-Inside_Outside-Rep_-Moncton.ashx",
			},
		],
	},
	fr: {
		title: "Postes ouverts - Canada",
		button: {
			title: "Open Positions - USA",
			link: "/about-us/careers/open-positions/",
		},
		description: [
			"Ryerson is 4,000 people strong, working in more than 100 locations around the world, and backed by more than 180 years in business. Throughout our history, we’ve always been firmly driven to create value for the customers, investors and communities we serve. You can see it in the ingenuity of our people. In the collective strength of our expertise. And, most evidently, in the driving mission that lays the foundation for our success and guides our everyday work.",
			"In business 178 years in North America, we offer a diverse range of metal products to our clients, including stainless, aluminum, carbon and alloy, flat/rolled/sheet products, nickel, brass and copper and pipes, valves and fittings.",
			"We offer exciting career opportunities in a variety of fields and promote from within, while tapping outside talent in order to diversify our strengths.  Our employees are our most valuable asset, so we offer competitive salary and benefits, along with an exciting, challenging atmosphere in a team environment.",
			"Whatever talents you have to offer, we have a place for you. Career opportunities in administration, finance, accounting, credit and purchasing exist at our Corporate offices in Ontario, while sales, operations, processing and distribution opportunities exist in any of our eight service centres located across Canada.",
			"Ryerson Canada is an Equal Opportunity Employer. We are committed to representing the diverse communities we serve. That’s why we encourage applications from visible minorities, aboriginal people, women, and people with disabilities.",
			"We are always looking for people with passion and drive who wish to share their unique talents. Interested in a career with Ryerson Canada? Please forward your resume to ",
		],
        contactEmail: "HRCanada@ryerson.com",
        contactEmailURL: "mailto:HRCanada@ryerson.com",
		futureOpportunitiesHeader: "Example future career opportunities:",
		futureOpportunititesList: [
			"Sales (Inside/Outside and Sales Management)",
			"Operations (General Labour, Machine Operators, Logistics, Production Planning, Supervision and Management)",
			"Finance and Accounting (Accounts Payable and Accounts Receivable)",
			"Credit (Collection and Cash Application)",
			"Purchasing (Inventory Management and Buyers)",
			"Administration (General and Assisting)",
		],
		currentOpportunitiesHeader: "Current Hiring Opportunities:",
		currentOpportunitiesList: [
			{
				title: "Order Picker/Packer - Vaudreuil, QC",
				link: "https://www.ryerson.com/-/media/Canada-Positions/071621/Warehouse-Operative_Vaudreuil.ashx",
			},
			{
				title: "Order Picker/Packer Vaudreuil, QC (FR)",
				link: "https://www.ryerson.com/-/media/Canada-Positions/071621/Warehouse-Operative_Vaudreuil_Fr.ashx",
			},
			{
				title: "Multi-Market Operations Manager - Brampton, ON",
				link: "https://www.ryerson.com/-/media/Canada-Positions/020222/MMOM_Canada_-Brampton.ashx",
			},
			{
				title: "Bilingual Outside Sales Representative - Moncton, NB",
				link: "https://www.ryerson.com/-/media/Canada-Positions/020222/Bilingual-Inside_Outside-Rep_-Moncton.ashx",
			},
		],
	},
	es: {
		title: "Posiciones abiertas - Canada",
		button: {
			title: "Open Positions - USA",
			link: "/about-us/careers/open-positions/",
		},
		description: [
			"Ryerson is 4,000 people strong, working in more than 100 locations around the world, and backed by more than 180 years in business. Throughout our history, we’ve always been firmly driven to create value for the customers, investors and communities we serve. You can see it in the ingenuity of our people. In the collective strength of our expertise. And, most evidently, in the driving mission that lays the foundation for our success and guides our everyday work.",
			"In business 178 years in North America, we offer a diverse range of metal products to our clients, including stainless, aluminum, carbon and alloy, flat/rolled/sheet products, nickel, brass and copper and pipes, valves and fittings.",
			"We offer exciting career opportunities in a variety of fields and promote from within, while tapping outside talent in order to diversify our strengths.  Our employees are our most valuable asset, so we offer competitive salary and benefits, along with an exciting, challenging atmosphere in a team environment.",
			"Whatever talents you have to offer, we have a place for you. Career opportunities in administration, finance, accounting, credit and purchasing exist at our Corporate offices in Ontario, while sales, operations, processing and distribution opportunities exist in any of our eight service centres located across Canada.",
			"Ryerson Canada is an Equal Opportunity Employer. We are committed to representing the diverse communities we serve. That’s why we encourage applications from visible minorities, aboriginal people, women, and people with disabilities.",
			"We are always looking for people with passion and drive who wish to share their unique talents.  Interested in a career with Ryerson Canada? Please forward your resume to ",
		],
        contactEmail: "HRCanada@ryerson.com",
        contactEmailURL: "mailto:HRCanada@ryerson.com",
		futureOpportunitiesHeader: "Example future career opportunities:",
		futureOpportunititesList: [
			"Sales (Inside/Outside and Sales Management)",
			"Operations (General Labour, Machine Operators, Logistics, Production Planning, Supervision and Management)",
			"Finance and Accounting (Accounts Payable and Accounts Receivable)",
			"Credit (Collection and Cash Application)",
			"Purchasing (Inventory Management and Buyers)",
			"Administration (General and Assisting)",
		],
		currentOpportunitiesHeader: "Current Hiring Opportunities:",
		currentOpportunitiesList: [
			{
				title: "Order Picker/Packer - Vaudreuil, QC",
				link: "https://www.ryerson.com/-/media/Canada-Positions/071621/Warehouse-Operative_Vaudreuil.ashx",
			},
			{
				title: "Order Picker/Packer Vaudreuil, QC (FR)",
				link: "https://www.ryerson.com/-/media/Canada-Positions/071621/Warehouse-Operative_Vaudreuil_Fr.ashx",
			},
			{
				title: "Multi-Market Operations Manager - Brampton, ON",
				link: "https://www.ryerson.com/-/media/Canada-Positions/020222/MMOM_Canada_-Brampton.ashx",
			},
			{
				title: "Bilingual Outside Sales Representative - Moncton, NB",
				link: "https://www.ryerson.com/-/media/Canada-Positions/020222/Bilingual-Inside_Outside-Rep_-Moncton.ashx",
			},
		],
	},
};

export const PageContent: StaticPageContent = {
	meta: MetaObject,
	content: [OpenPositionsContent],
};
