import { LanguageContent } from "@components/Shared/model/LanguageContent";
import { HeroImageProps } from "./HeroImage";

export const HeroContent: LanguageContent<HeroImageProps> = {
	en: {
		imageUrl: "/HeroImage.png",
		titleLine1: "Order \n metal online,",
		titleLine2: "anytime.",
		subtitle: "70,000+ products. \n 180+ years of experience.",
		welcome: "Welcome Back,",
		heroSubTitle1: "Search for a specific item or see your",
		orders: "orders",
		and: "and",
		products: "products",
	},
	fr: {
		imageUrl: "/HeroImage.png",
		titleLine1: "Commandez \n du métal en ligne,",
		titleLine2: "à tout moment.",
		subtitle: "70 000+ produits. \n 180+ ans d'expérience.",
		welcome: "Bienvenue,",
		heroSubTitle1: "Cherchez un article spécifique ou consultez vos",
		orders: "commandes",
		and: "et",
		products: "produits",
	},
	es: {
		imageUrl: "/HeroImage.png",
		titleLine1: "Ordenar \n metal en línea,",
		titleLine2: "en cualquier momento.",
		subtitle: "70,000+ productos. \n 180+ años de experiencia.",
		welcome: "Bienvenide,",
		heroSubTitle1: "Busque un articulo en particular o vea sus",
		orders: "pedidos",
		and: "y",
		products: "productos",
	},
};
