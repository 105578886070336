import { StaticPageContent } from "@components/Shared/model/StaticPageContent";
import { LanguageContent } from "@components/Shared/model/LanguageContent";
import { ValueAddedBenefitsProperties } from "@components/Industries/ValueAddedBenefits/ValueAddedBenefits";
import { HeroProps } from "@components/Industries/Hero/Hero";
import { CustomizedMetalSolutionsProps } from "@components/Industries/CustomizedMetalSolutions/CustomizedMetalSolutions";
import { RelatedSolutionsCapabilitiesAndProductsProperties } from "@components/Industries/RelatedSolutionsCapabilitiesAndProducts/RelatedSolutionsCapabilitiesAndProducts";
import { MetaContent } from "@components/Shared/model/MetaHelmet";

export const MetaObject: LanguageContent<MetaContent> = {
	en: {
		title: "Steel For Heavy Equipment Manufacturing - Ryerson",
		description:
			"Metals for construction, agriculture, and mining manufacturers. Full service steel plate, sheet, bar, and tube processing and fabrication.",
		url: `/metal-solutions/industries/heavy-equipment`,
		imageUrl: "/images/industries/HeavyEquipmentThumbnail.jpg",
		company: true,
	},
	fr: {
		title: "Steel For Heavy Equipment Manufacturing - Ryerson",
		description:
			"Metals for construction, agriculture, and mining manufacturers. Full service steel plate, sheet, bar, and tube processing and fabrication.",
		url: `/metal-solutions/industries/heavy-equipment`,
		imageUrl: "/images/industries/HeavyEquipmentThumbnail.jpg",
		company: true,
	},
	es: {
		title: "Steel For Heavy Equipment Manufacturing - Ryerson",
		description:
			"Metals for construction, agriculture, and mining manufacturers. Full service steel plate, sheet, bar, and tube processing and fabrication.",
		url: `/metal-solutions/industries/heavy-equipment`,
		imageUrl: "/images/industries/HeavyEquipmentThumbnail.jpg",
		company: true,
	},
};

export const HeroContent: LanguageContent<HeroProps> = {
	en: {
		heroTitle: "Heavy Equipment",
		heroDescription: [
			"No matter your position in the heavy equipment supply chain, the reality of doing business today is higher volumes, shorter lead times, and thinner margins.",
			"Ryerson helps ensure supply continuity and delivers finished and semi-finished parts as needed from dedicated locations near where you do business.",
		],
		buttonLabel: "Contact a Professional",
		hasVideo: true,
		heroVideoId: "sTgfVPmmx3I",
		thumbnailUrl: "/images/industries/HeavyEquipmentThumbnail.jpg",
		isWatermarkHero: true,
		watermarkUrl: "/images/industries/HeavyEquipmentWatermark.jpg",
	},
	fr: {
		heroTitle: "Machinerie lourde",
		heroDescription: [
			"Peu importe votre rôle dans la chaîne d'approvisionnement de la machinerie lourde, les volumes plus élevés, les délais d'approvisionnement plus courts et les marges plus minces font partie de la réalité des entreprises de nos jours.",
			"Ryerson contribue à assurer la continuité de l'approvisionnement et livre des pièces finies et semi-finies selon les besoins, dans des emplacements dédiés à proximité de votre entreprise.",
		],
		buttonLabel: "Communiquer avec un expert",
		hasVideo: true,
		heroVideoId: "sTgfVPmmx3I",
		thumbnailUrl: "/images/industries/HeavyEquipmentThumbnail.jpg",
		isWatermarkHero: true,
		watermarkUrl: "/images/industries/HeavyEquipmentWatermark.jpg",
	},
	es: {
		heroTitle: "Equipamiento pesado",
		heroDescription: [
			"No importa en qué posición se encuentre en la cadena de suministro del equipamiento pesado: la realidad de los negocios hoy en día es que lidian con volúmenes más altos, tiempos de espera más cortos y márgenes más ajustados.",
			"Ryerson le ayuda a garantizar la continuidad del suministro, y le ofrece piezas acabadas y semiacabadas según lo necesite de ubicaciones especializadas cerca de su empresa.",
		],
		buttonLabel: "Ponerse en contacto con un experto",
		hasVideo: true,
		heroVideoId: "sTgfVPmmx3I",
		thumbnailUrl: "/images/industries/HeavyEquipmentThumbnail.jpg",
		isWatermarkHero: true,
		watermarkUrl: "/images/industries/HeavyEquipmentWatermark.jpg",
	},
};

export const ValueAddedBenefitsContent: LanguageContent<ValueAddedBenefitsProperties> = {
	en: {
		title: "Value-Added Benefits",
		mainDescription: `Manufacturing heavy equipment requires engineered-to-order parts that are machined and processed in a cost-efficient manner. Ryerson has dedicated locations near you that reduce logistics costs.`,
		buttonLabel: "Let's Talk!",
		secondaryContent: [
			{
				title: "Dedicated Processing Facilities",
				description: `Our dedicated plate processing centers offer fast and reliable turnaround for anything from simple cut profiles to complex fabrications.`,
				imageUrl: "/images/industries/heavy-equipment/DedicatedLocationsUpdate3.jpg",
				showImageOnDesktop: false,
				showImageOnMobile: true,
			},
			{
				title: "Advanced Processing",
				description: `When you need solutions to complex challenges, our team offers more than just a list of fabrication capabilities. Fully invested in your success, we evaluate each step of the process and offer custom solutions focused on saving you time and money.`,
				imageUrl: "/images/industries/heavy-equipment/AdvancedProcessingInvertColors.jpg",
				showImageOnDesktop: true,
				showImageOnMobile: true,
			},
		],
	},
	fr: {
		title: "Avantages à valeur ajoutée",
		mainDescription: `La fabrication de machinerie lourde nécessite des pièces conçues sur demande qui sont usinées et traitées selon un processus rentable. Ryerson possède des emplacements dédiés près de vous pour réduire vos coûts de logistique.`,
		buttonLabel: "Discutons",
		secondaryContent: [
			{
				title: "Installations de traitement dédiées",
				description: `Nos centres de traitement des plaques proposent des délais d'exécution rapides et fiables, quelle que soit la tâche, de la coupe de profilés simples à la fabrication de pièces complexes.`,
				imageUrl: "/images/industries/heavy-equipment/DedicatedLocationsUpdate3.jpg",
				showImageOnDesktop: false,
				showImageOnMobile: true,
			},
			{
				title: "Transformation évoluée",
				description: `Lorsque vous avez besoin de solutions répondant à des défis complexes, notre équipe propose plus qu'une simple liste de capacités de fabrication. Nous avons à cœur votre succès. C'est pourquoi nous évaluons chaque étape du processus et vous offrons des solutions personnalisées pour vous faire économiser du temps et de l'argent.`,
				imageUrl: "/images/industries/heavy-equipment/AdvancedProcessingInvertColors.jpg",
				showImageOnDesktop: true,
				showImageOnMobile: true,
			},
		],
	},
	es: {
		title: "Beneficios de valor agregado",
		mainDescription: `La fabricación de equipamiento pesado requiere piezas diseñadas bajo pedido que se fabriquen y procesen de una forma rentable. Ryerson tiene ubicaciones especializadas cercanas a usted que le permiten reducir los costos de logística.`,
		buttonLabel: "Hablemos",
		secondaryContent: [
			{
				title: "Instalaciones de procesamiento especializadas",
				description: `Nuestros centros especializados de procesamiento de placas ofrecen plazos de entrega rápidos y fiables para cualquier servicio, desde sencillos perfiles de corte hasta fabricaciones complejas.`,
				imageUrl: "/images/industries/heavy-equipment/DedicatedLocationsUpdate3.jpg",
				showImageOnDesktop: false,
				showImageOnMobile: true,
			},
			{
				title: "Procesamiento avanzado",
				description: `Cuando necesite soluciones para retos difíciles, nuestro equipo le ofrece mucho más que una simple lista de capacidades de fabricación. Totalmente comprometidos con su éxito, evaluamos todos los pasos del proceso y ofrecemos soluciones personalizadas pensadas para que ahorre tiempo y dinero.`,
				imageUrl: "/images/industries/heavy-equipment/AdvancedProcessingInvertColors.jpg",
				showImageOnDesktop: true,
				showImageOnMobile: true,
			},
		],
	},
};

export const CustomizedMetalSolutionsContent: LanguageContent<CustomizedMetalSolutionsProps> = {
	en: {
		sectionHeading: "Customized Metal Solutions for",
		title: "Heavy Equipment",
		mainDescription: `Our industry professionals take the reins of your project and develop a metal solution embedded in best practices for heavy equipment. It's an approach that is the differentiator between just a solution and a customized metal solution from Ryerson.`,
		contact: {
			heading: "Contact your Heavy Equipment Professional",
			button: "Contact a Professional",
			name: "Rudi Tanck",
			title: "Market Manager — Heavy Equipment",
			email: "Rudi.Tanck@ryerson.com",
			portrait: "/images/industries/distribution-systems/Rudi.jpg",
		},
		thinglink: {
			description: `Ryerson has a team dedicated to heavy equipment. They are actively solving problems for companies like yours by delivering the material and designing a supply chain that addresses a range of needs.`,
			id: "1142145939406848002",
			width: 960,
			height: 640.0766100071822,
			originalHeight: 2785,
			originalWidth: 4177,
		},
	},
	fr: {
		sectionHeading: "Des solutions métallurgiques personnalisées pour",
		title: "Machinerie lourde",
		mainDescription: `Nos spécialistes du secteur prennent les rênes de votre projet et mettent au point des produits métallurgiques personnalisés qui intègrent les meilleures pratiques en matière de machinerie lourde. Cette approche fait toute la différence entre une simple solution et les produits métallurgiques personnalisés de Ryerson.`,
		contact: {
			heading: "Communiquer avec votre expert en équipement lourd",
			button: "Communiquer avec un expert",
			name: "Rudi Tanck",
			title: "Gestionnaire de marché - Industrie lourde",
			email: "Rudi.Tanck@ryerson.com",
			portrait: "/images/industries/distribution-systems/Rudi.jpg",
		},
		thinglink: {
			description: `Ryerson possède une équipe spécialisée dans la machinerie lourde. Elle peut résoudre de manière proactive des problèmes pour des entreprises comme la vôtre en fournissant les matériaux et en concevant une chaîne d'approvisionnement qui répond à divers besoins.`,
			id: "1142145939406848002",
			width: 960,
			height: 640.0766100071822,
			originalHeight: 2785,
			originalWidth: 4177,
		},
	},
	es: {
		sectionHeading: "Soluciones de metal personalizadas para",
		title: "Equipamiento pesado",
		mainDescription: `Nuestros expertos de la industria toman las riendas de su proyecto y desarrollan una solución de metal personalizada integrada en las mejores prácticas para equipamiento pesado. Es un enfoque que marca la diferencia entre una solución sin más y una solución de metal personalizada de Ryerson.`,
		contact: {
			heading: "Ponerse en contacto con un experto de equipamiento pesado",
			button: "Ponerse en contacto con un experto",
			name: "Rudi Tanck",
			title: "Director de mercado, industria de equipamiento pesado",
			email: "Rudi.Tanck@ryerson.com",
			portrait: "/images/industries/distribution-systems/Rudi.jpg",
		},
		thinglink: {
			description: `Ryerson tiene un equipo dedicado al equipamiento pesado. Ellos están trabajando activamente para resolver problemas para empresas como la suya al suministrar el material y diseñar una cadena de suministro que se enfoque en un rango de necesidades.`,
			id: "1142145939406848002",
			width: 960,
			height: 640.0766100071822,
			originalHeight: 2785,
			originalWidth: 4177,
		},
	},
};

export const RelatedSolutionsCapabilitiesContent: LanguageContent<RelatedSolutionsCapabilitiesAndProductsProperties> =
	{
		en: {
			title: "Related Solutions, Capabilities, and Products",
			mainDescription: `We get it, you need specific metal grades that meet the tough 
            requirements of your heavy equipment applications, and solutions that help 
            customize the project to your specifications.`,
			secondaryContent: [
				{
					title: "Solutions & Capabilities",
					jumpToLabel: "More Metal Solutions",
					jumpToLink: "/metal-solutions/",
					bullets: [
						{
							title: "Plasma",
							iconType: "capabilities-plate-laser",
							link: "/metal-solutions/capabilities/plate-processing/burning",
						},
						{
							title: "Forming",
							link: "/metal-solutions/capabilities/fabrication",
							iconType: "capabilities-fabrication",
						},
						{
							title: "Tube Laser",
							iconType: "capabilities-tube-laser",
							link: "/metal-solutions/capabilities/bar-tube-structural-processing/tube-laser",
						},
					],
				},
				{
					title: "Products",
					jumpToLabel: "More Metal Products",
					jumpToLink: "/store",
					bullets: [
						{
							title: "Steel Plate",
							imageUrl: "/static-assets/images/renderings/CarbonPlate.png",
							link: "/steel/steel-plate",
						},
						{
							title: "Steel Tube",
							imageUrl: "/static-assets/images/renderings/CarbonTubeRound.png",
							link: "/steel/steel-tube-pipe",
						},
						{
							title: "Steel Bar",
							imageUrl: "/static-assets/images/renderings/CarbonBarRound.png",
							link: "/steel/steel-bar",
						},
					],
				},
			],
		},
		fr: {
			title: "Solutions, capacités et produits connexes",
			mainDescription: `Nous sommes conscients que vous avez besoin de grades de métal spécifiques pour répondre aux exigences rigoureuses de votre machinerie lourde ainsi que de solutions permettant de personnaliser le projet en fonction de vos spécifications.`,
			secondaryContent: [
				{
					title: "Solutions et capacités",
					jumpToLabel: "Plus de solutions métallurgiques et de capacités",
					jumpToLink: "/metal-solutions/",
					bullets: [
						{
							title: "Plasma",
							iconType: "capabilities-plate-laser",
							link: "/metal-solutions/capabilities/plate-processing/burning",
						},
						{
							title: "Formage",
							link: "/metal-solutions/capabilities/fabrication",
							iconType: "capabilities-fabrication",
						},
						{
							title: "Laser de découpe de tubes",
							iconType: "capabilities-tube-laser",
							link: "/metal-solutions/capabilities/bar-tube-structural-processing/tube-laser",
						},
					],
				},
				{
					title: "Des produits",
					jumpToLabel: "Plus de produits métalliques",
					jumpToLink: "/store",
					bullets: [
						{
							title: "Plaque d'acier",
							imageUrl: "/static-assets/images/renderings/CarbonPlate.png",
							link: "/steel/steel-plate",
						},
						{
							title: "Barre d'acier",
							imageUrl: "/static-assets/images/renderings/CarbonTubeRound.png",
							link: "/steel/steel-tube-pipe",
						},
						{
							title: "Barre de carbone",
							imageUrl: "/static-assets/images/renderings/CarbonBarRound.png",
							link: "/steel/steel-bar",
						},
					],
				},
			],
		},
		es: {
			title: "Soluciones, capacidades y productos relacionados",
			mainDescription: `Lo comprendemos; necesita metales de grados específicos que se ajusten a los requisitos que exigen sus aplicaciones de equipamiento pesado, así como soluciones que le ayuden a personalizar el proyecto según sus especificaciones.`,
			secondaryContent: [
				{
					title: "Soluciones y capacidades",
					jumpToLabel: "Más soluciones y capacidades de metal",
					jumpToLink: "/metal-solutions/",
					bullets: [
						{
							title: "Plasma",
							iconType: "capabilities-plate-laser",
							link: "/metal-solutions/capabilities/plate-processing/burning",
						},
						{
							title: "Formado",
							link: "/metal-solutions/capabilities/fabrication",
							iconType: "capabilities-fabrication",
						},
						{
							title: "Corte con láser de tubos",
							iconType: "capabilities-tube-laser",
							link: "/metal-solutions/capabilities/bar-tube-structural-processing/tube-laser",
						},
					],
				},
				{
					title: "Productos",
					jumpToLabel: "Más productos de metal",
					jumpToLink: "/store",
					bullets: [
						{
							title: "Placa de acero",
							imageUrl: "/static-assets/images/renderings/CarbonPlate.png",
							link: "/steel/steel-plate",
						},
						{
							title: "Tubo de acero",
							imageUrl: "/static-assets/images/renderings/CarbonTubeRound.png",
							link: "/steel/steel-tube-pipe",
						},
						{
							title: "Barra de acero",
							imageUrl: "/static-assets/images/renderings/CarbonBarRound.png",
							link: "/steel/steel-bar",
						},
					],
				},
			],
		},
	};

export const PageContent: StaticPageContent = {
	meta: MetaObject,
	content: [
		HeroContent,
		ValueAddedBenefitsContent,
		RelatedSolutionsCapabilitiesContent,
		CustomizedMetalSolutionsContent,
	],
};
