import { StaticPageContent } from "@components/Shared/model/StaticPageContent";
import { LanguageContent } from "@components/Shared/model/LanguageContent";
import { ValueAddedBenefitsProperties } from "@components/Industries/ValueAddedBenefits/ValueAddedBenefits";
import { HeroProps } from "@components/Industries/Hero/Hero";
import { RelatedSolutionsCapabilitiesAndProductsProperties } from "@components/Industries/RelatedSolutionsCapabilitiesAndProducts/RelatedSolutionsCapabilitiesAndProducts";
import { MetaContent } from "@components/Shared/model/MetaHelmet";

export const MetaObject: LanguageContent<MetaContent> = {
	en: {
		title: "High-Performance Metals for Climate Solutions - Ryerson",
		description:
			"Discover cutting-edge metals and alloys for renewable energy, electric vehicles, and green infrastructure. Building a sustainable future with advanced materials.",
		url: "/climate",
		imageUrl: "/images/industries/ClimateThumbnail.jpg",
		company: true,
	},
	fr: {
		title: "High-Performance Metals for Climate Solutions - Ryerson",
		description:
			"Discover cutting-edge metals and alloys for renewable energy, electric vehicles, and green infrastructure. Building a sustainable future with advanced materials.",
		url: "/climate",
		imageUrl: "/images/industries/ClimateThumbnail.jpg",
		company: true,
	},
	es: {
		title: "High-Performance Metals for Climate Solutions - Ryerson",
		description:
			"Discover cutting-edge metals and alloys for renewable energy, electric vehicles, and green infrastructure. Building a sustainable future with advanced materials.",
		url: "/climate",
		imageUrl: "/images/industries/ClimateThumbnail.jpg",
		company: true,
	},
};

export const HeroContent: LanguageContent<HeroProps> = {
	en: {
		heroTitle: "Climate",
		heroDescription: [
			"Do you manufacture products that impact climate control or air movement such as HVAC systems, fans, blowers, heat exchangers, water treatment systems, large scale duct work, solar, wind, and related products?",
			"If so, perhaps it's time for a temperature check on your metal needs. Our market professionals will work with you on a broad inventory of coil, bar and tube, sheet, and plate products, as well as extensive in-house processing capabilities that are localized to you.",
		],
		buttonLabel: "Contact a Professional",
		hasVideo: true,
		heroVideoId: "4OCcKQJlraY",
		thumbnailUrl: "/images/industries/ClimateThumbnail.jpg",
		isWatermarkHero: true,
		watermarkUrl: "/images/industries/ClimateThumbnail.jpg",
	},
	fr: {
		heroTitle: "Climat",
		heroDescription: [
			"Fabriquez-vous des produits dans le secteur de la régulation du climat ou du mouvement de l’air, tels que des systèmes CVC, des ventilateurs, des soufflantes, des échangeurs de chaleur, des systèmes de traitement de l’eau, des conduites à grande échelle, des produits solaires, éoliens ou connexes?",
			"Si tel est le cas, il est peut-être temps d’évaluer vos besoins en métal. Nos experts du marché travailleront avec vous et vous proposeront un large stock de bobines, barres, tubes, feuilles et plaques, ainsi que de vastes capacités de transformation interne adaptée à vos besoins.",
		],
		buttonLabel: "Communiquer avec un expert",
		hasVideo: true,
		heroVideoId: "4OCcKQJlraY",
		thumbnailUrl: "/images/industries/ClimateThumbnail.jpg",
		isWatermarkHero: true,
		watermarkUrl: "/images/industries/ClimateThumbnail.jpg",
	},
	es: {
		heroTitle: "Control de la temperatura",
		heroDescription: [
			"¿Fabrica productos que afectan el control de la temperatura o el movimiento del aire, como sistemas HVAC, ventiladores, sopladores, intercambiadores de calor, sistemas de tratamiento de agua, sistemas de conductos a gran escala, energía solar, eólica y otros productos relacionados?",
			"De ser así, quizás es momento de verificar sus necesidades de metales. Nuestros expertos de mercado trabajarán con usted en un amplio inventario de productos de rollo, barra y tubo, hoja y placa, además de extensas capacidades de procesamiento internas adaptadas para usted.",
		],
		buttonLabel: "Ponerse en contacto con un experto",
		hasVideo: true,
		heroVideoId: "4OCcKQJlraY",
		thumbnailUrl: "/images/industries/ClimateThumbnail.jpg",
		isWatermarkHero: true,
		watermarkUrl: "/images/industries/ClimateThumbnail.jpg",
	},
};

export const ValueAddedBenefitsContent: LanguageContent<ValueAddedBenefitsProperties> = {
	en: {
		title: "Value-Added Benefits",
		mainDescription: `With dedicated processing locations in your market, Ryerson can produce and deliver the parts you need, when you need them. Our processing experts can help customize a solution for you.`,
		buttonLabel: "Let's Talk!",
		secondaryContent: [
			{
				title: "Advanced Processing",
				description: `When you need solutions to complex challenges, our team offers more than just a list of fabrication capabilities. Fully invested in your success, we evaluate each step of the process and offer custom solutions focused on saving you time and money.`,
				imageUrl: "/images/industries/heavy-equipment/AdvancedProcessingInvertColors.jpg",
				showImageOnDesktop: true,
				showImageOnMobile: true,
			},
			{
				title: "Inventory Programs",
				description: `No need to carry the inventory. With a just-in-time delivery program coupled with our broad network, we can fulfill requests as needed.`,
				imageUrl: "/images/industries/ev/Inventory.jpg",
				showImageOnDesktop: true,
				showImageOnMobile: true,
			},
		],
	},
	fr: {
		title: "Avantages à valeur ajoutée",
		mainDescription: `Grâce à des installations de transformation spécialisées dans votre secteur, Ryerson peut produire et livrer les pièces dont vous avez besoin, quand vous en avez besoin. Nos experts en transformation peuvent vous aider à élaborer une solution personnalisée.`,
		buttonLabel: "Discutons",
		secondaryContent: [
			{
				title: "Transformation évoluée",
				description: `Lorsque vous avez besoin de solutions répondant à des défis complexes, notre équipe propose plus qu’une simple liste de capacités de fabrication. Nous avons à cœur votre succès. C’est pourquoi nous évaluons chaque étape du processus et vous offrons des solutions personnalisées pour vous faire économiser du temps et de l’argent.`,
				imageUrl: "/images/industries/heavy-equipment/AdvancedProcessingInvertColors.jpg",
				showImageOnDesktop: true,
				showImageOnMobile: true,
			},
			{
				title: "Programmes de gestion des stocks",
				description: `Plus besoin de maintenir des stocks grâce à un programme de livraison juste à temps qui, associé à notre vaste réseau, répond à vos demandes en fonction de vos besoins précis.`,
				imageUrl: "/images/industries/ev/Inventory.jpg",
				showImageOnDesktop: false,
				showImageOnMobile: true,
			},
		],
	},
	es: {
		title: "Ventajas de valor añadido",
		mainDescription: `Con ubicaciones de procesamiento dedicadas en su mercado, Ryerson puede producir y entregar las partes que necesita, cuando las necesita. Nuestros expertos en procesamiento pueden ayudarle a personalizar una solución para usted.`,
		buttonLabel: "Hablemos",
		secondaryContent: [
			{
				title: "Procesamiento avanzado",
				description: `Cuando necesita soluciones para retos difíciles, nuestro equipo le ofrece mucho más que una simple lista de capacidades de fabricación. Totalmente comprometidos con su éxito, evaluamos todos los pasos del proceso y ofrecemos soluciones personalizadas pensadas para que ahorre tiempo y dinero.`,
				imageUrl: "/images/industries/heavy-equipment/AdvancedProcessingInvertColors.jpg",
				showImageOnDesktop: true,
				showImageOnMobile: true,
			},
			{
				title: "Programas de inventario",
				description: `No hay necesidad de llevar el inventario. Con un programa de entregas justo a tiempo que, combinado con con nuestra amplia red, puede atender las solicitudes según sea necesario.`,
				imageUrl: "/images/industries/ev/Inventory.jpg",
				showImageOnDesktop: false,
				showImageOnMobile: true,
			},
		],
	},
};

export const RelatedSolutionsCapabilitiesContent: LanguageContent<RelatedSolutionsCapabilitiesAndProductsProperties> =
	{
		en: {
			title: "Related Solutions, Capabilities, and Products",
			mainDescription: `Our broad selection of material, coupled with our market-leading set of processing and fabrication solutions, is a breath of fresh air to your ability to get parts done faster.`,
			secondaryContent: [
				{
					title: "Solutions & Capabilities",
					jumpToLabel: "More Metal Solutions",
					jumpToLink: "/metal-solutions/",
					bullets: [
						{
							title: "Tube Laser",
							iconType: "capabilities-tube-laser",
							link: "/metal-solutions/capabilities/bar-tube-structural-processing/tube-laser",
						},
						{
							title: "Plate Processing",
							link: "/metal-solutions/capabilities/plate-processing/",
							iconType: "solution-fabrication",
						},
						{
							title: "Fabrication",
							link: "/metal-solutions/capabilities/fabrication/",
							iconType: "capabilities-fabrication",
						},
					],
				},
				{
					title: "Products",
					jumpToLabel: "More Metal Products",
					jumpToLink: "/store",
					bullets: [
						{
							title: "Steel Sheet",
							imageUrl: "/static-assets/images/renderings/CarbonPlate.png",
							link: "/steel/steel-sheet",
						},
						{
							title: "Stainless Sheet",
							imageUrl: "/static-assets/images/renderings/StainlessSheet.png",
							link: "/stainless/stainless-sheet",
						},
						{
							title: "Stainless Plate",
							imageUrl: "/static-assets/images/renderings/StainlessPlate.png",
							link: "/stainless/stainless-plate",
						},
					],
				},
			],
		},
		fr: {
			title: "Solutions, capacités et produits connexes",
			mainDescription: `Notre vaste sélection de matériaux, associée à notre ensemble de solutions de transformation et de fabrication de premier plan, est une bouffée d’air frais qui vous aidera à réaliser des pièces plus rapidement.`,
			secondaryContent: [
				{
					title: "Solutions et capacités",
					jumpToLabel: "Plus de solutions métallurgiques et de capacités",
					jumpToLink: "/metal-solutions/",
					bullets: [
						{
							title: "Laser à tubes",
							iconType: "capabilities-tube-laser",
							link: "/metal-solutions/capabilities/bar-tube-structural-processing/tube-laser",
						},
						{
							title: "Transformation de plaques",
							link: "/metal-solutions/capabilities/plate-processing/",
							iconType: "solution-fabrication",
						},
						{
							title: "Fabrication",
							link: "/metal-solutions/capabilities/fabrication/",
							iconType: "capabilities-fabrication",
						},
					],
				},
				{
					title: "Produits",
					jumpToLabel: "Plus de produits métalliques",
					jumpToLink: "/store",
					bullets: [
						{
							title: "Feuille d’acier",
							imageUrl: "/static-assets/images/renderings/CarbonPlate.png",
							link: "/steel/steel-sheet",
						},
						{
							title: "Feuille d’acier inoxydable",
							imageUrl: "/static-assets/images/renderings/StainlessSheet.png",
							link: "/stainless/stainless-sheet",
						},
						{
							title: "Plaque d’acier inoxydable",
							imageUrl: "/static-assets/images/renderings/StainlessPlate.png",
							link: "/stainless/stainless-plate",
						},
					],
				},
			],
		},
		es: {
			title: "Soluciones, capacidades y productos relacionados",
			mainDescription: `Nuestra amplia selección de material, combinada con nuestro conjunto de soluciones de procesamiento y fabricación líder en el mercado, es un respiro para su capacidad de obtener partes rápidamente.`,
			secondaryContent: [
				{
					title: "Soluciones y capacidades",
					jumpToLabel: "Más soluciones y capacidades de metal",
					jumpToLink: "/metal-solutions/",
					bullets: [
						{
							title: "Corte con láser de tubos",
							iconType: "capabilities-tube-laser",
							link: "/metal-solutions/capabilities/bar-tube-structural-processing/tube-laser",
						},
						{
							title: "Procesamiento de placas",
							link: "/metal-solutions/capabilities/plate-processing/",
							iconType: "solution-fabrication",
						},
						{
							title: "Fabricación",
							link: "/metal-solutions/capabilities/fabrication/",
							iconType: "capabilities-fabrication",
						},
					],
				},
				{
					title: "Productos",
					jumpToLabel: "Más productos de metal",
					jumpToLink: "/store",
					bullets: [
						{
							title: "Hoja de acero",
							imageUrl: "/static-assets/images/renderings/CarbonPlate.png",
							link: "/steel/steel-sheet",
						},
						{
							title: "Hoja de Acero Inoxidable",
							imageUrl: "/static-assets/images/renderings/StainlessSheet.png",
							link: "/stainless/stainless-sheet",
						},
						{
							title: "Placa de Acero Inoxidable",
							imageUrl: "/static-assets/images/renderings/StainlessPlate.png",
							link: "/stainless/stainless-plate",
						},
					],
				},
			],
		},
	};

export const PageContent: StaticPageContent = {
	meta: MetaObject,
	content: [HeroContent, ValueAddedBenefitsContent, RelatedSolutionsCapabilitiesContent],
};
