import { PhoneNumbers } from "@ryerson/common.constants";
import { YourMetalExpertsStaticContent } from "./YourMetalExperts";
import { LanguageContent } from "@components/Shared/model/LanguageContent";
import { Company } from "@ryerson/common.enums";

const phoneNumbersCompany =
	PhoneNumbers.PHONE_NUMBERS[(process.env.GATSBY_COMPANY || "ryerson") as Company];

export const YourStaticContent: LanguageContent<YourMetalExpertsStaticContent> = {
	en: {
		titleNotLoggedIn: "Your Local Metal Professionals in",
		titleLoggedIn: "Your Local Metal Professionals for",
		navLabel: "Your Local Metal Professionals",
		questions: "Questions? Call us on",
		phoneNumber: phoneNumbersCompany.us.number,
		talkToAMetalExpert: "Talk to a \n Metal Professional",
		seeAllOffices: "See all \n Offices",
		localOffice: "Local Office",
	},
	fr: {
		titleNotLoggedIn: "Vos experts des métaux locaux à",
		titleLoggedIn: "Vos experts locaux en métal pour",
		navLabel: "Vos experts des métaux locaux",
		questions: "Des questions? Appelez-nous au",
		phoneNumber: phoneNumbersCompany.ca.number,
		talkToAMetalExpert: "Parler à un \n expert des métaux",
		seeAllOffices: "Voir tous \n les bureaux",
		localOffice: "Bureau local",
	},
	es: {
		titleNotLoggedIn: "Sus expertos en metal locales en",
		titleLoggedIn: "Sus expertos locales en metales para",
		navLabel: "Sus expertos locales en metales",
		questions: "¿Preguntas? llámenos al",
		phoneNumber: phoneNumbersCompany.mx.number,
		talkToAMetalExpert: "Hable con un \n experto en metales",
		seeAllOffices: "Ver todas \n las oficinas",
		localOffice: "Oficina local",
	},
};
