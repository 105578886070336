import React from "react";
import { useTheme } from "@ryerson/frontend.theme";
import { Typography } from "@ryerson/frontend.typography";
import { ContentSection, SplitConfig, Flex, FlexItem } from "@ryerson/frontend.layout";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { ButtonLink } from "@ryerson/frontend.navigation";
import { MetalMarketIntelligenceProps } from "../MetalMarketIntelligence";
import { Badge } from "@ryerson/frontend.notification";
import { BlogBadgeColor, TranslateBlogCategory } from "@components/Blog/Blog";
import { Link } from "gatsby";

const IntelDiv = styled.div`
	position: relative;
	display: flex;
	width: 33.33%;
`;

const BadgeContainer = styled.div`
	position: absolute;
	z-index: 1;
	width: 200px;
	left: 15%;
	bottom: 25%;
`;

const Intel = (props: any) => {
	return (
		<IntelDiv>
			<Link to={"/metal-resources/metal-market-intelligence/#" + props.url} replace>
				<img
					src={props.imageUrl}
					css={css`
						width: 80px;
						height: auto;
						margin-bottom: 40px;
					`}
				/>
				<BadgeContainer>
					<Badge
						badgeType="pill"
						type="tertiary"
						color={BlogBadgeColor(TranslateBlogCategory(props.label))}
					>
						{props.label}
					</Badge>
				</BadgeContainer>
			</Link>
		</IntelDiv>
	);
};

const MetalMarketIntelligenceDesktop: React.FC<MetalMarketIntelligenceProps> = (props) => {
	const { theme } = useTheme();

	const headerImage: SplitConfig = {
		splitImage: [
			{
				imageUrl: props.imageUrl,
				percentage: "100%",
			},
		],
	};

	return (
		<>
			<ContentSection type="split" split={headerImage}>
				<Typography
					variant="h1"
					type="tertiary"
					css={css`
						margin-top: 110px;
						margin-bottom: 47px;
						@media only screen and (max-width: ${theme.breakpoints.xl}) {
							margin-top: 30px;
						}
					`}
				>
					{props.title}
				</Typography>
			</ContentSection>
			<ContentSection type="secondary">
				<Flex justifyContent="space-between">
					<FlexItem
						css={css`
							width: 250px;
						`}
					>
						<Typography
							variant="p"
							css={css`
								margin-top: 55px;
								margin-bottom: 137px;
							`}
						>
							{props.description}
						</Typography>
						<ButtonLink to={props.actionLink} label={props.actionText} />
					</FlexItem>
					<Flex
						direction="row"
						wrap="wrap"
						css={css`
							width: calc(100% - 250px);
							margin-left: 141px;
							margin-top: 55px;
							margin-bottom: 137px;
							@media only screen and (max-width: ${theme.breakpoints.xl}) {
								margin-left: 120px;
							}
						`}
					>
						{props.metalMarketIntelligenceTypes.map(function (
							content: any,
							index: number
						) {
							return <Intel theme={theme} {...content} />;
						})}
					</Flex>
				</Flex>
			</ContentSection>
		</>
	);
};
export default MetalMarketIntelligenceDesktop;
